import React from 'react'
import {Flex,Text} from "@radix-ui/themes"

const FAQP = ({Title,paragraph}) => {
  return (
    <div><Flex m="20px"><Text size="7">{Title}</Text></Flex>
        <Flex m="20px"><Text>{paragraph}</Text></Flex></div>
  )
}

export default FAQP