import React from 'react';
import { Flex, Text, Container,Card } from '@radix-ui/themes';
import ContactP from './ContactP';

const Contact = () => {
  return (
        
        <Container size="2">
        
        <Card style={{boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",}}>
          <Flex m="20px"><Text size="6" weight="bold">Informations Concernant le Voyage a Canada</Text></Flex>
          <ContactP title="L'AVE est l'acronyme pour Autorisation de Voyage Électronique. Il s'agit d'une autorisation électronique de voyage qui permet aux ressortissants des pays éligibles de se rendre au Canada pour des séjours de courte durée (90 jours maximum) à des fins touristiques, d'affaires ou de transit. Cette autorisation est obligatoire pour les voyageurs qui entrent au Canada par voie aérienne ou transite par le Canada."/>
          <ContactP title="La demande d'AVE se fait en ligne sur le site officiel d'Immigration, Réfugiés et Citoyenneté Canada (IRCC) et doit être effectuée avant le départ pour le Canada. La demande est rapide et facile à remplir et les voyageurs peuvent recevoir leur autorisation en quelques minutes. Il est recommandé de faire la demande d'AVE au moins 72 heures avant le départ pour le Canada."/>
          <ContactP title="La demande officielle d'AVE doit être effectuée sur ce site qui propose un formulaire en ligne pour remplir la demande d'AVE. Les voyageurs doivent fournir des informations personnelles telles que leur nom, leur date de naissance, leur adresse et leur numéro de passeport. Ils doivent également répondre à des questions relatives à leur état de santé, leur casier judiciaire et leurs antécédents de voyage. Il est important de fournir des informations exactes et à jour lors de la demande d'AVE pour éviter tout refus d'autorisation ou problème à l'entrée au Canada."/>
          <ContactP title="Vous avez besoin d’aide concernant votre demande d’AVE ?

N’hésitez pas à nous contacter directement au 06 10 10 10 10 ou par courriel à :

serviceclient@serviceclient.fr"/>
          <ContactP title="Les parcs nationaux au Canada sont des espaces naturels protégés qui sont gérés par Parcs Canada, une agence gouvernementale. Il existe plus de 40 parcs nationaux au Canada, offrant des paysages spectaculaires allant des montagnes rocheuses aux forêts boréales en passant par les plages de l'océan Atlantique.

Les parcs nationaux sont des lieux de prédilection pour les activités de plein air, comme la randonnée, le camping, la pêche et l'observation de la faune et de la flore. Ils sont également importants pour la préservation de la biodiversité et pour la protection des espèces en danger.

Les visiteurs des parcs nationaux doivent respecter les règles de Parcs Canada pour préserver les écosystèmes fragiles et la sécurité de tous. Les permis d'entrée et les frais d'utilisation sont requis pour accéder à certains parcs et installations. Les visiteurs peuvent obtenir plus d'informations sur les parcs nationaux et les réservations sur le site Web de Parcs Canada.

Lieux historiques nationaux

Les lieux historiques nationaux au Canada sont des sites importants pour l'histoire et la culture du pays. Ils sont gérés par Parcs Canada, une agence gouvernementale, et il en existe plus de 170 dans tout le pays.

Ces lieux comprennent des bâtiments historiques, des monuments, des sites archéologiques, des parcs commémoratifs et des lieux de batailles importantes. Chaque lieu a une histoire unique qui est liée à l'histoire et au développement du Canada.

Les visiteurs des lieux historiques nationaux peuvent en apprendre davantage sur l'histoire et la culture du Canada grâce à des programmes éducatifs, des visites guidées, des expositions et des présentations multimédias. Ils peuvent également explorer les lieux de manière autonome.

Les frais d'entrée pour les lieux historiques nationaux varient et certains sont gratuits. Les visiteurs peuvent obtenir plus d'informations sur les lieux historiques nationaux et les heures d'ouverture sur le site Web de Parcs Canada.

Aires marines nationales de conservation au Canada

Les aires marines nationales de conservation (AMNC) au Canada sont des zones marines protégées qui sont gérées par Parcs Canada, une agence gouvernementale. Ces zones ont été créées pour protéger et conserver la biodiversité marine et les habitats naturels du pays.

Il y a cinq AMNC au Canada, chacune avec des caractéristiques uniques et des objectifs de conservation spécifiques. Les AMNC comprennent des îles, des récifs, des baies et des fjords, ainsi que des espèces marines telles que des baleines, des phoques, des oiseaux de mer et des poissons.

Les visiteurs des AMNC peuvent participer à des activités éducatives et récréatives telles que la randonnée, le kayak, la plongée en apnée et la pêche sportive, tout en respectant les réglementations de conservation en place. Les visiteurs peuvent également en apprendre davantage sur la biodiversité marine et les mesures de conservation grâce à des programmes d'interprétation et des présentations.

Les frais d'entrée pour les AMNC varient selon le lieu et certaines zones peuvent être fermées pendant certaines périodes de l'année pour protéger la faune et la flore. Les visiteurs peuvent obtenir plus d'informations sur les AMNC et les heures d'ouverture sur le site Web de Parcs Canada.

Musées et galeries

Il y a de nombreux musées et galeries à visiter au Canada. Voici quelques exemples :

Le Musée des beaux-arts du Canada à Ottawa
La Galerie d'art de l'Ontario à Toronto
Le Musée des beaux-arts de Montréal
Le Musée canadien de l'histoire à Gatineau
Le Musée canadien pour les droits de la personne à Winnipeg
Le Musée royal de la Colombie-Britannique à Victoria
Le Musée des civilisations à Québec
Il existe de nombreux autres musées et galeries à travers le pays, offrant une variété d'expositions et d'expériences culturelles.

Événements, célébrations et commémorations

Le Canada célèbre de nombreuses fêtes nationales, événements et commémorations tout au long de l'année. Voici quelques exemples :

La fête du Canada, qui a lieu le 1er juillet de chaque année, célèbre la création du pays en 1867.
La Journée nationale des Autochtones, qui a lieu le 21 juin de chaque année, célèbre la culture et la contribution des peuples autochtones au Canada.
Le Jour du Souvenir, qui a lieu le 11 novembre de chaque année, commémore les militaires canadiens qui ont combattu et sont morts pendant les guerres.
Le Carnaval de Québec, qui a lieu en février, est l'un des plus grands festivals d'hiver au monde.
Le Festival des tulipes à Ottawa, qui a lieu en mai, célèbre les tulipes envoyées chaque année par les Pays-Bas en signe de gratitude pour l'aide apportée pendant la Seconde Guerre mondiale.
Le Festival de Jazz de Montréal, qui a lieu en juin, est l'un des plus grands festivals de jazz au monde.
Il existe de nombreux autres événements, célébrations et commémorations à travers le pays, reflétant la diversité et la richesse de la culture canadienne.

Vivez au rythme de la région de la capitale du Canada

La région de la capitale du Canada, également connue sous le nom d'Ottawa-Gatineau, offre une multitude d'activités et d'expériences pour tous les goûts.

Que vous aimiez l'histoire, la culture, les sports, la nature ou la cuisine, vous trouverez certainement quelque chose à votre goût dans cette région dynamique. Voici quelques-unes des choses à ne pas manquer :

Visitez les édifices du Parlement canadien et découvrez l'histoire politique du Canada
Promenez-vous dans le marché By, l'un des plus anciens marchés publics du pays, pour y découvrir une variété de produits locaux et artisanaux
Découvrez l'art et l'histoire canadiens au Musée des beaux-arts du Canada, au Musée canadien de l'histoire ou au musée de la Guerre
Assistez à un match de hockey des Sénateurs d'Ottawa ou à un spectacle au Centre national des arts
Profitez de la nature en faisant une randonnée dans le parc de la Gatineau ou en vous promenant le long du canal Rideau
Dégustez les spécialités locales dans l'un des nombreux restaurants de la région, en vous arrêtant pour goûter la poutine, un plat emblématique du Canada.
Peu importe ce que vous choisissez de faire, la région de la capitale du Canada est un endroit animé et passionnant à découvrir.

Explorer le Canada

Le Canada est un pays vaste et diversifié offrant une multitude d'expériences de voyage. Les voyageurs peuvent explorer les grandes villes comme Toronto, Montréal et Vancouver, ainsi que les vastes espaces naturels, tels que les montagnes Rocheuses, les lacs de l'Ontario et les parcs nationaux comme Banff et Jasper.

Les activités populaires comprennent la randonnée, le camping, le ski, le kayak, la pêche, le tourisme culturel et l'observation de la faune.

Les voyageurs peuvent également découvrir la riche culture autochtone et visiter des sites historiques importants tels que la Citadelle de Québec, le lieu historique national de la Bataille-de-la-Ristigouche et le lieu historique national de la Voie-Navigable-Trent-Severn. Il y a quelque chose pour tout le monde au Canada, que vous soyez un amoureux de la nature, un passionné d'histoire ou un adepte de la vie urbaine.

Carte d’entrée Découverte : accès à plusieurs sites du Canada

La carte d'entrée Découverte est une carte qui permet aux visiteurs d'accéder à plusieurs sites touristiques et culturels du Canada. Cette carte est destinée aux voyageurs qui souhaitent explorer le pays et découvrir ses nombreuses attractions.

La carte d'entrée Découverte donne accès à plus de 100 sites gérés par Parcs Canada, comme les parcs nationaux, les lieux historiques nationaux et les aires marines nationales de conservation. Elle permet également l'entrée à des sites gérés par d'autres organisations, tels que des musées et des jardins botaniques.

La carte d'entrée Découverte est valide pour une période d'un an à partir de la date d'achat. Elle est disponible en ligne sur le site web de Parcs Canada, ainsi que dans certains sites d'accueil des parcs nationaux et des lieux historiques nationaux. Les visiteurs peuvent acheter une carte d'entrée individuelle ou une carte d'entrée familiale, qui permet l'accès à un maximum de sept personnes voyageant ensemble dans un seul véhicule.

La carte d'entrée Découverte est un excellent moyen de découvrir les merveilles du Canada, tout en réalisant des économies sur les frais d'entrée à chaque site visité.

Passeport des musées de la capitale du Canada

Le Passeport des musées de la capitale du Canada est un billet combiné qui donne accès à trois musées à Ottawa, la capitale du Canada : le Musée des beaux-arts du Canada, le Musée canadien de l'histoire et le musée de la Guerre.

Avec ce passeport, les visiteurs peuvent visiter chaque musée une fois pendant une période de trois jours. Il est disponible à l'achat en ligne et dans les musées participants.

Planifiez votre voyage scolaire dans la capitale du Canada

Si vous prévoyez un voyage scolaire dans la capitale du Canada, il est important de planifier à l'avance pour tirer le meilleur parti de votre expérience. Voici quelques étapes clés pour planifier votre voyage :

Déterminez les dates de votre voyage et le nombre de participants.
Réservez votre hébergement à l'avance pour obtenir les meilleurs tarifs et l'emplacement idéal pour votre groupe.
Choisissez les attractions que vous souhaitez visiter, telles que les musées, les monuments et les parcs nationaux, et assurez-vous de réserver des visites guidées si nécessaire.
Organisez le transport pour votre groupe, que ce soit en bus, en train ou en avion.
Préparez une liste de contrôle pour vous assurer que vous avez tout ce dont vous avez besoin pour votre voyage, notamment des documents de voyage, des médicaments et des vêtements appropriés pour les activités prévues.
Il peut également être utile de travailler avec un voyagiste spécialisé dans les voyages scolaires pour vous aider à planifier votre voyage et à répondre à toutes vos questions.

Musées et galeries dans la capitale du Canada

Il y a plusieurs musées et galeries dans la capitale du Canada, Ottawa. Voici quelques-uns d'entre eux :

Le Musée canadien de l'histoire (anciennement le Musée canadien des civilisations) : ce musée est situé à Gatineau, de l'autre côté de la rivière Ottawa. Il abrite des expositions sur l'histoire et la culture du Canada, ainsi que sur les civilisations du monde entier.
Le Musée des beaux-arts du Canada : ce musée abrite une vaste collection d'œuvres d'art canadiennes et internationales, allant de la peinture à la sculpture en passant par la photographie et les arts décoratifs.
La Galerie d'art d'Ottawa : cette galerie se trouve dans le centre-ville d'Ottawa et propose des expositions temporaires d'art contemporain et moderne, ainsi qu'une collection permanente d'art canadien.
Le musée de la Guerre du Canada : ce musée retrace l'histoire militaire du Canada, depuis les guerres autochtones jusqu'aux opérations de maintien de la paix dans le monde entier.
Le Centre national des Arts : ce centre culturel est l'un des plus grands du genre au Canada. Il propose des spectacles de danse, de théâtre, de musique et d'autres formes d'art.
Le musée de la Nature et des sciences de Gatineau : ce musée est situé à Gatineau et abrite des expositions sur la nature et les sciences, y compris une collection de fossiles de dinosaures.
Il y a bien sûr de nombreux autres musées et galeries à découvrir à Ottawa et dans les environs.

Ce que vous pouvez rapporter au Canada

Les voyageurs au Canada peuvent rapporter certains articles à des fins personnelles sans payer de droits et taxes à l'importation.

Les quantités autorisées varient selon le type d'article et le pays d'origine.

Les voyageurs doivent également déclarer tous les articles qu'ils rapportent et répondre aux questions des agents frontaliers sur leur voyage et les articles qu'ils transportent.

Les articles couramment autorisés incluent les vêtements, les souliers, les bijoux, les articles de toilette personnels, les livres, les magazines et les journaux. Les voyageurs peuvent également rapporter une certaine quantité d'alcool et de tabac à des fins personnelles, mais doivent être âgés de 18 ans ou plus pour le tabac et de 19 ans ou plus pour l'alcool.

Les voyageurs doivent être conscients que certains articles, tels que les armes à feu, les explosifs et les drogues illégales, sont strictement interdits à l'importation au Canada. Les voyageurs qui tentent d'importer de tels articles peuvent faire face à des poursuites judiciaires et à des peines sévères."/>
        </Card>
        </Container>
      
    
  );
};

export default Contact;