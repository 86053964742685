import React from 'react'
import {Text} from "@radix-ui/themes"
import CountryComp from '../CountryComp'

const CountryP = ({title,value,setfunction}) => {
  return (
    <div><Text>Pays ou territoire {title} *</Text>
                <CountryComp value={value} setfunction={setfunction}/>
</div>
  )
}

export default CountryP