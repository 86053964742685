import React, { useState } from 'react';
import { Card, Text, Radio, Container, Flex } from "@radix-ui/themes";

const Step2 = ({ onRadioChange, selectedRadioValue, onMinorChange }) => {
  const [minorAdultValue, setMinorAdultValue] = useState(null);

  const handleRadioChange = (event) => {
    onRadioChange(event.target.value);
  };

  const handleMinorAdultChange = (event) => {
    const value = event.target.value;
    setMinorAdultValue(value);
    onMinorChange(value);
  };

  return (
    <Container size="2">
      <Card style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }} m="20px" height="264px">
        <div style={{ margin: "20px" }}>
          <Text size="5">Identité de Demande</Text>
        </div>
        <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', margin: '5px', marginBottom: '20px', marginTop: '20px' }}>
          <div style={{ marginTop: "20px", marginLeft: "20px" }}>
            <Text>Effectuez-vous une demande pour une autre personne? *</Text>
            <Flex mt="12px" asChild gap="2">
              <Text as="label" size="2">
                <Radio size="1" name="alignment-1" value="1" onChange={handleRadioChange} checked={selectedRadioValue === '1'} />
                Oui
              </Text>
            </Flex>
            <Flex mb="12px" asChild gap="2">
              <Text as="label" size="2">
                <Radio size="1" name="alignment-1" value="2" onChange={handleRadioChange} checked={selectedRadioValue === '2'} />
                Non
              </Text>
            </Flex>
            {selectedRadioValue === '1' && (
              <div>
                <Text>Faites-vous une demande au nom d'un enfant mineur? *</Text>
                <Flex mt="12px" asChild gap="2">
                  <Text as="label" size="2">
                    <Radio size="1" name="alignment-2" value="minor" onChange={handleMinorAdultChange} checked={minorAdultValue === 'minor'} />
                    Oui
                  </Text>
                </Flex>
                <Flex asChild gap="2">
                  <Text as="label" size="2">
                    <Radio size="1" name="alignment-2" value="adult" onChange={handleMinorAdultChange} checked={minorAdultValue === 'adult'} />
                    Non
                  </Text>
                </Flex>
              </div>
            )}
          </div>
        </div>
      </Card>
    </Container>
  );
}

export default Step2;
