import { Card, Text, Radio, Container } from "@radix-ui/themes";
import { FaShip, FaCar, FaPlane } from 'react-icons/fa';

const Step1 = ({ onRadioChange, selectedRadioValue }) => {
  const handleRadioChange = (event) => {
    onRadioChange(event.target.value);
  };

  return (
    <Container size="2">
      <Card m="20px" style={{boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",}}>
        <div style={{ margin: "20px" }}>
          <Text size="5">
            C'est quoi la nature de votre voyage ?
          </Text>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '5px', marginBottom: '20px', marginTop: '20px' }}>
          <Card m="5px">
            <Radio size="1" name="size-1" value="maritime" checked={selectedRadioValue === 'maritime'} onChange={handleRadioChange} />
            <div style={{ textAlign: 'center' }}>
              <FaShip style={{ fontSize: '24px', display: 'block', marginBottom: '5px', color: '#DD3C3C' }} />
              <Text>Voie maritime</Text>
            </div>
          </Card>
          <Card m="5px">
            <Radio size="1" name="size-1" value="terrestre" checked={selectedRadioValue === 'terrestre'} onChange={handleRadioChange} />
            <div style={{ textAlign: 'center' }}>
              <FaCar style={{ fontSize: '24px', display: 'block', marginBottom: '5px', color: '#DD3C3C' }} />
              <Text>Voie terrestre</Text>
            </div>
          </Card>
          <Card m="5px">
            <Radio size="1" name="size-1" value="aerienne" checked={selectedRadioValue === 'aerienne'} onChange={handleRadioChange} />
            <div style={{ textAlign: 'center' }}>
              <FaPlane style={{ fontSize: '24px', display: 'block', marginBottom: '5px', color: '#DD3C3C' }} />
              <Text>Voie aérienne</Text>
            </div>
          </Card>
        </div>
      </Card>
    </Container>
  );
};

export default Step1;
