import React, { useEffect, useState } from 'react';
import { Card, Container, Text } from "@radix-ui/themes";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { API_URL } from './../../config/index';

const Success = () => {
  const [uniqueId, setUniqueId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Retrieve the uniqueId from localStorage
    const id = localStorage.getItem('uniqueId');
    setUniqueId(id);

    if (id) {
      // Send a request to the backend with the id
      const updatePaymentSuccess = async () => {
        try {
          const response = await fetch(`${API_URL}/update-payment-success/${id}/`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
          });

          if (response.ok) {
            const responseData = await response.json();
            console.log('Success:', responseData);
          } else {
            const errorData = await response.json();
            console.error('Error:', errorData);
            setError('Failed to update payment status.');
            navigate('/Cancel'); // Redirect to /Cancel in case of error
          }
        } catch (error) {
          console.error('Error:', error);
          setError('An error occurred while updating payment status.');
          navigate('/Cancel'); // Redirect to /Cancel in case of error
        } finally {
          setIsLoading(false);
        }
      };

      updatePaymentSuccess();
    } else {
      setIsLoading(false);
      setError('Unique ID not found.');
      navigate('/Cancel'); // Redirect to /Cancel if uniqueId is not found
    }
  }, [navigate]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <Container size="2">
        <Card m="40px" p="20px">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FontAwesomeIcon icon={faCheckCircle} size="3x" color="green" style={{ marginRight: '20px' }} />
            <div>
              <Text size="5">Paiement réussi</Text>
              <p>Voici l'identifiant de cette opération, gardez-le : <strong>{uniqueId}</strong></p>
              <p>Notre support vous contactera dès que possible.</p>
            </div>
          </div>
        </Card>
      </Container>
    </div>
  );
};

export default Success;
