import React from 'react'
import {Flex,Text,Radio} from "@radix-ui/themes"

const VisaPast = ({handleRadioChange2}) => {
  return (
    <div><Text>Avez-vous déjà demandé un visa, une AVE ou un permis pour visiter, habiter, travailler ou étudier au Canada?</Text>
              <Flex ml="20px" mt="12px" asChild gap="2">
                    <Text as="label" size="2">
                      <Radio
                        size="1"
                        name="iscitizen"
                        value="1"
                        onValueChange={() => handleRadioChange2("1")}
                      />
                      Oui
                    </Text>
                  </Flex>
                  <Flex ml="20px" mb="12px" asChild gap="2">
                    <Text as="label" size="2">
                      <Radio
                        size="1"
                        name="iscitizen"
                        value="2"
                        onValueChange={() => handleRadioChange2("2")}
                      />
                      Non
                    </Text>
                  </Flex></div>
  )
}

export default VisaPast