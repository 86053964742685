import React from 'react'
import {Flex,Text,Radio} from "@radix-ui/themes"

const Languague = ({handleLanguageChange}) => {
  return (
    <div><Flex>
            <Text>Langue de correspandance préférée</Text></Flex>
            <Flex ml="20px" mt="12px" asChild gap="2" alignItems="center">
                    <Text as="label" size="2">
                      <Radio
                        size="1"
                        name="langue"
                        value="1"
                        onChange={() => handleLanguageChange("Français")}
                      />
                      Français
                      <Radio
                        size="1"
                        name="langue"
                        value="2"
                        onChange={() => handleLanguageChange("Anglais")}
                      />Anglais
                    </Text>
                  </Flex></div>
  )
}

export default Languague