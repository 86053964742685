import React from 'react'
import {Flex,Text} from "@radix-ui/themes"
import DatePickerTime from '../DatePickerTime';

const DateDetail = ({onSelectDate}) => {
  return (
    <>
        <Flex>
            <Text mb="20px">Quand avez-vous l'intention de vous rendre au Canada? *</Text>
        </Flex>
        <Flex>
            <DatePickerTime onSelectDate={onSelectDate}/>
        </Flex>
        <Text size="2" weight="regular">Entrez la date et l'heure du vol prévu (donnez des informations approximatives en cas d'incertitude).</Text>
    </>
  )
}

export default DateDetail