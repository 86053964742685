import React from 'react'
import {Text,Flex,Box,TextField} from "@radix-ui/themes"

const IdInput = ({isVisible,value,onChange}) => {
  return (
    <div className={`fade-in ${isVisible ? 'visible' : ''}`}>
          <Text>
            Identificateur unique de client (IUC) / Numéro de permis, AVE ou de visa canadien précédent (facultatif)
          </Text>
          <Flex mt="12px" mb="12px" direction="column" maxWidth="240px">
            <Box maxWidth="240px">
              <TextField.Root
                size="2"
                placeholder=""
                name="memberId"
                value={value}
                onChange={(e) => onChange(e.target.value)}
              />
            </Box>
          </Flex>
        </div>
  )
}

export default IdInput