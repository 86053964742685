import { Card, Container, Text } from "@radix-ui/themes";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';


const Cancel = () => {
  
    return (
        <div>
          <Container size="2">
            <Card m="40px" p="20px">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <FontAwesomeIcon icon={faTimesCircle} size="3x" color="red" style={{ marginRight: '20px' }} />
                <div>
                  <Text size="5">Paiement échoué</Text>
                  <p>Nous sommes désolés, mais votre paiement a échoué. Veuillez réessayer ou contacter notre support.</p>
                </div>
              </div>
            </Card>
          </Container>
        </div>
      );
    };
    
    export default Cancel;