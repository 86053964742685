import React from 'react'
import {Flex,Text,Radio} from '@radix-ui/themes'

const CitizenCheck = ({handleRadioChange}) => {
  return (
      <>
    <Flex mr="20px" ml="20px">
    <Text>Êtes-vous un résident permanent légitime des États-Unis possédant un numéro de carte valide des Services de citoyenneté et d'immigration des États-Unis (USCIS)? *</Text>
    </Flex>
    <Flex ml="20px" mt="12px" asChild gap="2">
    <Text as="label" size="2">
        <Radio
        size="1"
        name="iscitizen"
        value="1"
        onValueChange={() => handleRadioChange("1")}
        />
        Oui
    </Text>
    </Flex>
    <Flex ml="20px" mb="12px" asChild gap="2">
    <Text as="label" size="2">
        <Radio
        size="1"
        name="iscitizen"
        value="2"
        onValueChange={() => handleRadioChange("2")}
        />
        Non
    </Text>
    </Flex>
    </>
  )
}

export default CitizenCheck
