import React from 'react';
import ThreeBlockComponent from "./Three";
import Two from './Two';
import { Container } from '@radix-ui/themes';

const Home = () => {
  return (
    <Container>
      <div>
        <Two />
        <ThreeBlockComponent />
      </div>
    </Container>
  );
};

export default Home;