import React from 'react'
import {Box,Select,Flex,Text} from "@radix-ui/themes"

const Occupation = ({selectedValue2,handleSelectChange2}) => {
  return (
    <div><Box><Text >Occupation *</Text></Box>
                <Flex mt="12px" mb="12px" direction="column" maxWidth="240px">
                <Select.Root defaultValue="" value={selectedValue2} onValueChange={handleSelectChange2}>
                  <Select.Trigger placeholder="Veuillez sélectionner" variant="surface" />
                  <Select.Content>
                    <Select.Item value="0">Affaires, finance et administration</Select.Item>
                    <Select.Item value="1">Arts, culture, sports et loisirs</Select.Item>
                    <Select.Item value="2">Chômeur</Select.Item>
                    <Select.Item value="3">Emplois dans les ventes et les services</Select.Item>
                    <Select.Item value="4">Enseignement, droit et services sociaux, communautaires et gouvernementaux</Select.Item>
                    <Select.Item value="5">Étudiant</Select.Item>
                    <Select.Item value="6">Fabrication et services d'utilité publique</Select.Item>
                    <Select.Item value="7">Forces armées</Select.Item>
                    <Select.Item value="8">Gestion</Select.Item>
                    <Select.Item value="9">Métiers, transport, machinerie et domaines apparentés</Select.Item>
                    <Select.Item value="10">Personne au foyer</Select.Item>
                    <Select.Item value="11">Ressources naturelles, agriculture et production connexe</Select.Item>
                    <Select.Item value="12">Retraité</Select.Item>
                    <Select.Item value="13">Sciences naturelles et appliquées et domaines apparentés</Select.Item>
                    <Select.Item value="14">Secteur de la santé</Select.Item>
                  </Select.Content>
                </Select.Root></Flex></div>
  )
}

export default Occupation