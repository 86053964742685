import React from 'react'
import {Text,TextField,Flex,Box} from "@radix-ui/themes"

const CityInput = ({value,onChange}) => {
  return (
    <div style={{textAlign:"center", alignItems:"center"}}>                
    <Text >Ville de Naissance*</Text>
            <Flex align="center"mt="12px" mb="12px" direction="column" >
            <Box >
                <TextField.Root
                size="2"
                placeholder="..."
                name="memberId"
                value={value}
                onChange={(e) => onChange(e.target.value)}
                />
            </Box>
            </Flex>
    </div>
  )
}

export default CityInput