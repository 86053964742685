import React from 'react';
import {Flex,Select,Text} from '@radix-ui/themes';

const Passtype = ({selectedValue,handleSelectChange}) => {
  return (
    <>
    <Text>Quel document de voyage avez-vous l'intention d'utiliser pour venir au Canada ? * </Text>
    <Flex mt="12px" mb="12px" direction="column" maxWidth="240px">
    <Select.Root defaultValue="" value={selectedValue} onValueChange={handleSelectChange}>
        <Select.Trigger placeholder="Veuillez sélectionner" variant="surface" />
        <Select.Content>
            <Select.Item value="0">Passeport - ordinaire/régulier</Select.Item>
            <Select.Item value="1">Passeport - diplomatique</Select.Item>
            <Select.Item value="2">Passeport - officiel</Select.Item>
            <Select.Item value="3">Passeport - service</Select.Item>
            <Select.Item value="4">Document de voyage d'urgence/provisoire</Select.Item>
            <Select.Item value="5">Titre de voyage pour réfugié</Select.Item>
            <Select.Item value="6">Permis pour rentrer aux États-Unis (I-327)</Select.Item>
            <Select.Item value="7">Titre de voyage pour réfugiés des États-Unis (I-571)</Select.Item>
        </Select.Content>
    </Select.Root>
    </Flex>
    </>
  )
}

export default Passtype
