import React from 'react'
import {Box,Callout} from '@radix-ui/themes'
import { FaExclamationTriangle, } from 'react-icons/fa';

const Notpossible = () => {
  return (
    <div>
      <Box ml="20px" mt="0px" mb="35px" mr="20px" height="auto">
        <Callout.Root>
            <Callout.Icon>
            <FaExclamationTriangle />
            </Callout.Icon>
            <Callout.Text>
            Vos réponses indiquent que vous ne pouvez pas présenter de demande d'AVE. Il se pourrait que vous ayez besoin d'un visa ou d'un autre type de document de voyage pour venir au Canada.
            </Callout.Text>
        </Callout.Root>
      </Box>
    </div>
  )
}

export default Notpossible
