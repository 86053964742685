import React from 'react';
import { Flex, Text } from '@radix-ui/themes';
import Form from "./about/Form"

const About = () => {
  return (
    
      <Form/>
    
  );
};

export default About;