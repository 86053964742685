import React from 'react'
import {Box,Select,Flex,Text} from "@radix-ui/themes"

const MariState = ({value,setfunction}) => {
  return (
    <div><Box><Text >État matrimonial *</Text></Box>
                <Flex mt="12px" mb="12px" direction="column" maxWidth="240px">
                <Select.Root defaultValue="" value={value} onValueChange={setfunction}>
                  <Select.Trigger placeholder="Veuillez sélectionner" variant="surface" />
                  <Select.Content>
                    <Select.Item value="Marié">Marié</Select.Item>
                    <Select.Item value="Séparé légalement">Séparé légalement</Select.Item>
                    <Select.Item value="Divorcé">Divorcé</Select.Item>
                    <Select.Item value="Mariage annulé">Mariage annulé</Select.Item>
                    <Select.Item value="Veuf">Veuf</Select.Item>
                    <Select.Item value="Conjoint de fait">Conjoint de fait</Select.Item>
                    <Select.Item value="Jamais marié/célibataire">Jamais marié/célibataire</Select.Item>
                  </Select.Content>
                </Select.Root></Flex></div>
  )
}

export default MariState