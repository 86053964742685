import React from 'react'
import {Text,Radio,Flex} from "@radix-ui/themes"

const NextDate = ({handleRadioChange3}) => {
  return (
    <div><Text>Savez-vous quand vous partirez au Canada?</Text>
                <Flex ml="20px" mb="25px" mt="12px" asChild gap="2" alignItems="center">
                  <Text as="label" size="2">
                    <Radio
                      size="1"
                      name="when"
                      value="1"
                      onValueChange={() => handleRadioChange3("1")}
                    />
                    Oui
                    <Radio
                      size="1"
                      name="when"
                      value="2"
                      onValueChange={() => handleRadioChange3("2")}
                    />
                    Non
                  </Text>
                </Flex></div>
  )
}

export default NextDate