import React from 'react'
import { FaExclamationTriangle } from 'react-icons/fa';
import {Callout,Box} from "@radix-ui/themes"

const Forbiden = () => {
  return (
    <Box ml="20px" mt="20px" mb="35px" mr="20px" height="auto">
        <Callout.Root>
        <Callout.Icon>
            <FaExclamationTriangle />
        </Callout.Icon>
        <Callout.Text>
            Vos réponses indiquent que vous ne pouvez pas faire de demande d'AVE. Il est possible que vous ayez besoin d'un visa ou d'un autre type de document de voyage pour venir au Canada.
        </Callout.Text>
        </Callout.Root>
    </Box>
  )
}

export default Forbiden
