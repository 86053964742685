import React from 'react';
import { DatePicker, Space, ConfigProvider } from 'antd';
import dayjs from 'dayjs';



const App = ({onSelectDate}) => (
  <ConfigProvider
    theme={{
      token: {
        controlItemBgHover: '#f0f0f0', // Change the background color on hover
        colorPrimary: '#dd3c3c', // Change the primary color
      },
    }}
  >
    <Space direction="vertical">
      <DatePicker
        format={{
          format: 'YYYY-MM-DD',
          type: 'mask',
        }}
        onChange={onSelectDate}
        style={{
          width: '240px', // Set the width of the DatePicker
        }}
      />
    </Space>
  </ConfigProvider>
);

export default App;
