import {Flex,Select} from '@radix-ui/themes';

const CountryComp = ({value,setfunction}) => {
  return (
    <>
                
                <Flex mt="12px" mb="12px" direction="column" maxWidth="240px">
                <Select.Root defaultValue="" value={value} onValueChange={setfunction} >
                      <Select.Trigger placeholder="Veuillez sélectionner" variant="surface" />
                      <Select.Content>
                        <Select.Item value="AFG">Afghanistan</Select.Item>
                          <Select.Item value="ALA">Åland Islands</Select.Item>
                          <Select.Item value="ALB">Albania</Select.Item>
                          <Select.Item value="DZA">Algeria</Select.Item>
                          <Select.Item value="ASM">American Samoa</Select.Item>
                          <Select.Item value="AND">Andorra</Select.Item>
                          <Select.Item value="AGO">Angola</Select.Item>
                          <Select.Item value="AIA">Anguilla</Select.Item>
                          <Select.Item value="ATA">Antarctica</Select.Item>
                          <Select.Item value="ATG">Antigua and Barbuda</Select.Item>
                          <Select.Item value="ARG">Argentina</Select.Item>
                          <Select.Item value="ARM">Armenia</Select.Item>
                          <Select.Item value="ABW">Aruba</Select.Item>
                          <Select.Item value="AUS">Australia</Select.Item>
                          <Select.Item value="AUT">Austria</Select.Item>
                          <Select.Item value="AZE">Azerbaijan</Select.Item>
                          <Select.Item value="BHS">Bahamas</Select.Item>
                          <Select.Item value="BHR">Bahrain</Select.Item>
                          <Select.Item value="BGD">Bangladesh</Select.Item>
                          <Select.Item value="BRB">Barbados</Select.Item>
                          <Select.Item value="BLR">Belarus</Select.Item>
                          <Select.Item value="BEL">Belgium</Select.Item>
                          <Select.Item value="BLZ">Belize</Select.Item>
                          <Select.Item value="BEN">Benin</Select.Item>
                          <Select.Item value="BMU">Bermuda</Select.Item>
                          <Select.Item value="BTN">Bhutan</Select.Item>
                          <Select.Item value="BOL">Bolivia</Select.Item>
                          <Select.Item value="BIH">Bosnia and Herzegovina</Select.Item>
                          <Select.Item value="BWA">Botswana</Select.Item>
                          <Select.Item value="BVT">Bouvet Island</Select.Item>
                          <Select.Item value="BRA">Brazil</Select.Item>
                          <Select.Item value="IOT">British Indian Ocean Territory</Select.Item>
                          <Select.Item value="BRN">Brunei Darussalam</Select.Item>
                          <Select.Item value="BGR">Bulgaria</Select.Item>
                          <Select.Item value="BFA">Burkina Faso</Select.Item>
                          <Select.Item value="BDI">Burundi</Select.Item>
                          <Select.Item value="KHM">Cambodia</Select.Item>
                          <Select.Item value="CMR">Cameroon</Select.Item>
                          <Select.Item value="CAN">Canada</Select.Item>
                          <Select.Item value="CPV">Cape Verde</Select.Item>
                          <Select.Item value="CYM">Cayman Islands</Select.Item>
                          <Select.Item value="CAF">Central African Republic</Select.Item>
                          <Select.Item value="TCD">Chad</Select.Item>
                          <Select.Item value="CHL">Chile</Select.Item>
                          <Select.Item value="CHN">China</Select.Item>
                          <Select.Item value="CXR">Christmas Island</Select.Item>
                          <Select.Item value="CCK">Cocos (Keeling) Islands</Select.Item>
                          <Select.Item value="COL">Colombia</Select.Item>
                          <Select.Item value="COM">Comoros</Select.Item>
                          <Select.Item value="COG">Congo</Select.Item>
                          <Select.Item value="COD">Congo, The Democratic Republic of The</Select.Item>
                          <Select.Item value="COK">Cook Islands</Select.Item>
                          <Select.Item value="CRI">Costa Rica</Select.Item>
                          <Select.Item value="CIV">Cote D'ivoire</Select.Item>
                          <Select.Item value="HRV">Croatia</Select.Item>
                          <Select.Item value="CUB">Cuba</Select.Item>
                          <Select.Item value="CYP">Cyprus</Select.Item>
                          <Select.Item value="CZE">Czech Republic</Select.Item>
                          <Select.Item value="DNK">Denmark</Select.Item>
                          <Select.Item value="DJI">Djibouti</Select.Item>
                          <Select.Item value="DMA">Dominica</Select.Item>
                          <Select.Item value="DOM">Dominican Republic</Select.Item>
                          <Select.Item value="ECU">Ecuador</Select.Item>
                          <Select.Item value="EGY">Egypt</Select.Item>
                          <Select.Item value="SLV">El Salvador</Select.Item>
                          <Select.Item value="GNQ">Equatorial Guinea</Select.Item>
                          <Select.Item value="ERI">Eritrea</Select.Item>
                          <Select.Item value="EST">Estonia</Select.Item>
                          <Select.Item value="ETH">Ethiopia</Select.Item>
                          <Select.Item value="FLK">Falkland Islands (Malvinas)</Select.Item>
                          <Select.Item value="FRO">Faroe Islands</Select.Item>
                          <Select.Item value="FJI">Fiji</Select.Item>
                          <Select.Item value="FIN">Finland</Select.Item>
                          <Select.Item value="FRA">France</Select.Item>
                          <Select.Item value="GUF">French Guiana</Select.Item>
                          <Select.Item value="PYF">French Polynesia</Select.Item>
                          <Select.Item value="ATF">French Southern Territories</Select.Item>
                          <Select.Item value="GAB">Gabon</Select.Item>
                          <Select.Item value="GMB">Gambia</Select.Item>
                          <Select.Item value="GEO">Georgia</Select.Item>
                          <Select.Item value="DEU">Germany</Select.Item>
                          <Select.Item value="GHA">Ghana</Select.Item>
                          <Select.Item value="GIB">Gibraltar</Select.Item>
                          <Select.Item value="GRC">Greece</Select.Item>
                          <Select.Item value="GRL">Greenland</Select.Item>
                          <Select.Item value="GRD">Grenada</Select.Item>
                          <Select.Item value="GLP">Guadeloupe</Select.Item>
                          <Select.Item value="GUM">Guam</Select.Item>
                          <Select.Item value="GTM">Guatemala</Select.Item>
                          <Select.Item value="GGY">Guernsey</Select.Item>
                          <Select.Item value="GIN">Guinea</Select.Item>
                          <Select.Item value="GNB">Guinea-bissau</Select.Item>
                          <Select.Item value="GUY">Guyana</Select.Item>
                          <Select.Item value="HTI">Haiti</Select.Item>
                          <Select.Item value="HMD">Heard Island and Mcdonald Islands</Select.Item>
                          <Select.Item value="VAT">Holy See (Vatican City State)</Select.Item>
                          <Select.Item value="HND">Honduras</Select.Item>
                          <Select.Item value="HKG">Hong Kong</Select.Item>
                          <Select.Item value="HUN">Hungary</Select.Item>
                          <Select.Item value="ISL">Iceland</Select.Item>
                          <Select.Item value="IND">India</Select.Item>
                          <Select.Item value="IDN">Indonesia</Select.Item>
                          <Select.Item value="IRN">Iran, Islamic Republic of</Select.Item>
                          <Select.Item value="IRQ">Iraq</Select.Item>
                          <Select.Item value="IRL">Ireland</Select.Item>
                          <Select.Item value="IMN">Isle of Man</Select.Item>
                          <Select.Item value="ISR">Israel</Select.Item>
                          <Select.Item value="ITA">Italy</Select.Item>
                          <Select.Item value="JAM">Jamaica</Select.Item>
                          <Select.Item value="JPN">Japan</Select.Item>
                          <Select.Item value="JEY">Jersey</Select.Item>
                          <Select.Item value="JOR">Jordan</Select.Item>
                          <Select.Item value="KAZ">Kazakhstan</Select.Item>
                          <Select.Item value="KEN">Kenya</Select.Item>
                          <Select.Item value="KIR">Kiribati</Select.Item>
                          <Select.Item value="PRK">Korea, Democratic People's Republic of</Select.Item>
                          <Select.Item value="KOR">Korea, Republic of</Select.Item>
                          <Select.Item value="KWT">Kuwait</Select.Item>
                          <Select.Item value="KGZ">Kyrgyzstan</Select.Item>
                          <Select.Item value="LAO">Lao People's Democratic Republic</Select.Item>
                          <Select.Item value="LVA">Latvia</Select.Item>
                          <Select.Item value="LBN">Lebanon</Select.Item>
                          <Select.Item value="LSO">Lesotho</Select.Item>
                          <Select.Item value="LBR">Liberia</Select.Item>
                          <Select.Item value="LBY">Libyan Arab Jamahiriya</Select.Item>
                          <Select.Item value="LIE">Liechtenstein</Select.Item>
                          <Select.Item value="LTU">Lithuania</Select.Item>
                          <Select.Item value="LUX">Luxembourg</Select.Item>
                          <Select.Item value="MAC">Macao</Select.Item>
                          <Select.Item value="MKD">Macedonia, The Former Yugoslav Republic of</Select.Item>
                          <Select.Item value="MDG">Madagascar</Select.Item>
                          <Select.Item value="MWI">Malawi</Select.Item>
                          <Select.Item value="MYS">Malaysia</Select.Item>
                          <Select.Item value="MDV">Maldives</Select.Item>
                          <Select.Item value="MLI">Mali</Select.Item>
                          <Select.Item value="MLT">Malta</Select.Item>
                          <Select.Item value="MHL">Marshall Islands</Select.Item>
                          <Select.Item value="MTQ">Martinique</Select.Item>
                          <Select.Item value="MRT">Mauritania</Select.Item>
                          <Select.Item value="MUS">Mauritius</Select.Item>
                          <Select.Item value="MYT">Mayotte</Select.Item>
                          <Select.Item value="MEX">Mexico</Select.Item>
                          <Select.Item value="FSM">Micronesia, Federated States of</Select.Item>
                          <Select.Item value="MDA">Moldova, Republic of</Select.Item>
                          <Select.Item value="MCO">Monaco</Select.Item>
                          <Select.Item value="MNG">Mongolia</Select.Item>
                          <Select.Item value="MNE">Montenegro</Select.Item>
                          <Select.Item value="MSR">Montserrat</Select.Item>
                          <Select.Item value="MAR">Morocco</Select.Item>
                          <Select.Item value="MOZ">Mozambique</Select.Item>
                          <Select.Item value="MMR">Myanmar</Select.Item>
                          <Select.Item value="NAM">Namibia</Select.Item>
                          <Select.Item value="NRU">Nauru</Select.Item>
                          <Select.Item value="NPL">Nepal</Select.Item>
                          <Select.Item value="NLD">Netherlands</Select.Item>
                          <Select.Item value="ANT">Netherlands Antilles</Select.Item>
                          <Select.Item value="NCL">New Caledonia</Select.Item>
                          <Select.Item value="NZL">New Zealand</Select.Item>
                          <Select.Item value="NIC">Nicaragua</Select.Item>
                          <Select.Item value="NER">Niger</Select.Item>
                          <Select.Item value="NGA">Nigeria</Select.Item>
                          <Select.Item value="NIU">Niue</Select.Item>
                          <Select.Item value="NFK">Norfolk Island</Select.Item>
                          <Select.Item value="MNP">Northern Mariana Islands</Select.Item>
                          <Select.Item value="NOR">Norway</Select.Item>
                          <Select.Item value="OMN">Oman</Select.Item>
                          <Select.Item value="PAK">Pakistan</Select.Item>
                          <Select.Item value="PLW">Palau</Select.Item>
                          <Select.Item value="PSE">Palestinian Territory, Occupied</Select.Item>
                          <Select.Item value="PAN">Panama</Select.Item>
                          <Select.Item value="PNG">Papua New Guinea</Select.Item>
                          <Select.Item value="PRY">Paraguay</Select.Item>
                          <Select.Item value="PER">Peru</Select.Item>
                          <Select.Item value="PHL">Philippines</Select.Item>
                          <Select.Item value="PCN">Pitcairn</Select.Item>
                          <Select.Item value="POL">Poland</Select.Item>
                          <Select.Item value="PRT">Portugal</Select.Item>
                          <Select.Item value="PRI">Puerto Rico</Select.Item>
                          <Select.Item value="QAT">Qatar</Select.Item>
                          <Select.Item value="REU">Reunion</Select.Item>
                          <Select.Item value="ROU">Romania</Select.Item>
                          <Select.Item value="RUS">Russian Federation</Select.Item>
                          <Select.Item value="RWA">Rwanda</Select.Item>
                          <Select.Item value="SHN">Saint Helena</Select.Item>
                          <Select.Item value="KNA">Saint Kitts and Nevis</Select.Item>
                          <Select.Item value="LCA">Saint Lucia</Select.Item>
                          <Select.Item value="SPM">Saint Pierre and Miquelon</Select.Item>
                          <Select.Item value="VCT">Saint Vincent and The Grenadines</Select.Item>
                          <Select.Item value="WSM">Samoa</Select.Item>
                          <Select.Item value="SMR">San Marino</Select.Item>
                          <Select.Item value="STP">Sao Tome and Principe</Select.Item>
                          <Select.Item value="SAU">Saudi Arabia</Select.Item>
                          <Select.Item value="SEN">Senegal</Select.Item>
                          <Select.Item value="SRB">Serbia</Select.Item>
                          <Select.Item value="SYC">Seychelles</Select.Item>
                          <Select.Item value="SLE">Sierra Leone</Select.Item>
                          <Select.Item value="SGP">Singapore</Select.Item>
                          <Select.Item value="SVK">Slovakia</Select.Item>
                          <Select.Item value="SVN">Slovenia</Select.Item>
                          <Select.Item value="SLB">Solomon Islands</Select.Item>
                          <Select.Item value="SOM">Somalia</Select.Item>
                          <Select.Item value="ZAF">South Africa</Select.Item>
                          <Select.Item value="SGS">South Georgia and The South Sandwich Islands</Select.Item>
                          <Select.Item value="ESP">Spain</Select.Item>
                          <Select.Item value="LKA">Sri Lanka</Select.Item>
                          <Select.Item value="SDN">Sudan</Select.Item>
                          <Select.Item value="SUR">Suriname</Select.Item>
                          <Select.Item value="SJM">Svalbard and Jan Mayen</Select.Item>
                          <Select.Item value="SWZ">Swaziland</Select.Item>
                          <Select.Item value="SWE">Sweden</Select.Item>
                          <Select.Item value="CHE">Switzerland</Select.Item>
                          <Select.Item value="SYR">Syrian Arab Republic</Select.Item>
                          <Select.Item value="TWN">Taiwan</Select.Item>
                          <Select.Item value="TJK">Tajikistan</Select.Item>
                          <Select.Item value="TZA">Tanzania, United Republic of</Select.Item>
                          <Select.Item value="THA">Thailand</Select.Item>
                          <Select.Item value="TLS">Timor-leste</Select.Item>
                          <Select.Item value="TGO">Togo</Select.Item>
                          <Select.Item value="TKL">Tokelau</Select.Item>
                          <Select.Item value="TON">Tonga</Select.Item>
                          <Select.Item value="TTO">Trinidad and Tobago</Select.Item>
                          <Select.Item value="TUN">Tunisia</Select.Item>
                          <Select.Item value="TUR">Turkey</Select.Item>
                          <Select.Item value="TKM">Turkmenistan</Select.Item>
                          <Select.Item value="TCA">Turks and Caicos Islands</Select.Item>
                          <Select.Item value="TUV">Tuvalu</Select.Item>
                          <Select.Item value="UGA">Uganda</Select.Item>
                          <Select.Item value="UKR">Ukraine</Select.Item>
                          <Select.Item value="ARE">United Arab Emirates</Select.Item>
                          <Select.Item value="GBR">United Kingdom</Select.Item>
                          <Select.Item value="USA">United States</Select.Item>
                          <Select.Item value="UMI">United States Minor Outlying Islands</Select.Item>
                          <Select.Item value="URY">Uruguay</Select.Item>
                          <Select.Item value="UZB">Uzbekistan</Select.Item>
                          <Select.Item value="VUT">Vanuatu</Select.Item>
                          <Select.Item value="VEN">Venezuela</Select.Item>
                          <Select.Item value="VNM">Viet Nam</Select.Item>
                          <Select.Item value="VGB">Virgin Islands, British</Select.Item>
                          <Select.Item value="VIR">Virgin Islands, U.S.</Select.Item>
                          <Select.Item value="WLF">Wallis and Futuna</Select.Item>
                          <Select.Item value="ESH">Western Sahara</Select.Item>
                          <Select.Item value="YEM">Yemen</Select.Item>
                          <Select.Item value="ZMB">Zambia</Select.Item>
                          <Select.Item value="ZWE">Zimbabwe</Select.Item>
                      </Select.Content>
                    </Select.Root>
                </Flex>
            </>
  )
}

export default CountryComp