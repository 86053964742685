import React from 'react'
import { FaExclamationTriangle } from 'react-icons/fa';
import {Flex,Callout} from "@radix-ui/themes"

const NotAllowed = () => {
  return (
    <Flex mt="40px" mr="30px"><Callout.Root color="amber" variant='surface'>
        <Callout.Icon>
            <FaExclamationTriangle />
        </Callout.Icon>
        <Callout.Text>
            Seuls les pays suivants nécessitent une autorisation de voyage électronique (AVE) pour embarquer sur un vol à destination du Canada. Si votre pays ne figure pas dans la liste, vous n'êtes pas éligible. Veuillez vous rapprocher de l’ambassade du Canada de votre pays.
        </Callout.Text>
        </Callout.Root>
    </Flex>
  )
}

export default NotAllowed
