import React from 'react'
import {Flex,Select} from '@radix-ui/themes';

const YearComp = ({value,setfunction}) => {
  return (
    <>
    <Flex mt="12px" mb="12px" direction="column" maxWidth="240px">
        <Select.Root defaultValue="" value={value} onValueChange={setfunction} >
            <Select.Trigger placeholder="Veuillez sélectionner" variant="surface" />
            <Select.Content>
                <Select.Item value="2024">2024</Select.Item>
                <Select.Item value="2023">2023</Select.Item>
                <Select.Item value="2022">2022</Select.Item>
                <Select.Item value="2021">2021</Select.Item>
                <Select.Item value="2020">2020</Select.Item>
                <Select.Item value="2019">2019</Select.Item>
                <Select.Item value="2018">2018</Select.Item>
                <Select.Item value="2017">2017</Select.Item>
                <Select.Item value="2016">2016</Select.Item>
                <Select.Item value="2015">2015</Select.Item>
                <Select.Item value="2014">2014</Select.Item>
                <Select.Item value="2013">2013</Select.Item>
                <Select.Item value="2012">2012</Select.Item>
                <Select.Item value="2011">2011</Select.Item>
                <Select.Item value="2010">2010</Select.Item>
                <Select.Item value="2009">2009</Select.Item>
                <Select.Item value="2008">2008</Select.Item>
                <Select.Item value="2007">2007</Select.Item>
                <Select.Item value="2006">2006</Select.Item>
                <Select.Item value="2005">2005</Select.Item>
                <Select.Item value="2004">2004</Select.Item>
                <Select.Item value="2003">2003</Select.Item>
                <Select.Item value="2002">2002</Select.Item>
                <Select.Item value="2001">2001</Select.Item>
                <Select.Item value="2000">2000</Select.Item>
                <Select.Item value="1999">1999</Select.Item>
                <Select.Item value="1998">1998</Select.Item>
                <Select.Item value="1997">1997</Select.Item>
                <Select.Item value="1996">1996</Select.Item>
                <Select.Item value="1995">1995</Select.Item>
                <Select.Item value="1994">1994</Select.Item>
                <Select.Item value="1993">1993</Select.Item>
                <Select.Item value="1992">1992</Select.Item>
                <Select.Item value="1991">1991</Select.Item>
                <Select.Item value="1990">1990</Select.Item>
                <Select.Item value="1989">1989</Select.Item>
                <Select.Item value="1988">1988</Select.Item>
                <Select.Item value="1987">1987</Select.Item>
                <Select.Item value="1986">1986</Select.Item>
                <Select.Item value="1985">1985</Select.Item>
                <Select.Item value="1984">1984</Select.Item>
                <Select.Item value="1983">1983</Select.Item>
                <Select.Item value="1982">1982</Select.Item>
                <Select.Item value="1981">1981</Select.Item>
                <Select.Item value="1980">1980</Select.Item>
                <Select.Item value="1979">1979</Select.Item>
                <Select.Item value="1978">1978</Select.Item>
                <Select.Item value="1977">1977</Select.Item>
                <Select.Item value="1976">1976</Select.Item>
                <Select.Item value="1975">1975</Select.Item>
                <Select.Item value="1974">1974</Select.Item>
                <Select.Item value="1973">1973</Select.Item>
                <Select.Item value="1972">1972</Select.Item>
                <Select.Item value="1971">1971</Select.Item>
                <Select.Item value="1970">1970</Select.Item>
                <Select.Item value="1969">1969</Select.Item>
                <Select.Item value="1968">1968</Select.Item>
                <Select.Item value="1967">1967</Select.Item>
                <Select.Item value="1966">1966</Select.Item>
                <Select.Item value="1965">1965</Select.Item>
                <Select.Item value="1964">1964</Select.Item>
                <Select.Item value="1963">1963</Select.Item>
                <Select.Item value="1962">1962</Select.Item>
                <Select.Item value="1961">1961</Select.Item>
                <Select.Item value="1960">1960</Select.Item>
                <Select.Item value="1959">1959</Select.Item>
                <Select.Item value="1958">1958</Select.Item>
                <Select.Item value="1957">1957</Select.Item>
                <Select.Item value="1956">1956</Select.Item>
                <Select.Item value="1955">1955</Select.Item>
                <Select.Item value="1954">1954</Select.Item>
                <Select.Item value="1953">1953</Select.Item>
                <Select.Item value="1952">1952</Select.Item>
                <Select.Item value="1951">1951</Select.Item>
                <Select.Item value="1950">1950</Select.Item>
                <Select.Item value="1949">1949</Select.Item>
                <Select.Item value="1948">1948</Select.Item>
                <Select.Item value="1947">1947</Select.Item>
                <Select.Item value="1946">1946</Select.Item>
                <Select.Item value="1945">1945</Select.Item>
                <Select.Item value="1944">1944</Select.Item>
                <Select.Item value="1943">1943</Select.Item>
                <Select.Item value="1942">1942</Select.Item>
                <Select.Item value="1941">1941</Select.Item>
                <Select.Item value="1940">1940</Select.Item>
                <Select.Item value="1939">1939</Select.Item>
                <Select.Item value="1938">1938</Select.Item>
                <Select.Item value="1937">1937</Select.Item>
                <Select.Item value="1936">1936</Select.Item>
                <Select.Item value="1935">1935</Select.Item>
                <Select.Item value="1934">1934</Select.Item>
                <Select.Item value="1933">1933</Select.Item>
                <Select.Item value="1932">1932</Select.Item>
                <Select.Item value="1931">1931</Select.Item>
                <Select.Item value="1930">1930</Select.Item>
                <Select.Item value="1929">1929</Select.Item>
                <Select.Item value="1928">1928</Select.Item>
                <Select.Item value="1927">1927</Select.Item>
                <Select.Item value="1926">1926</Select.Item>
                <Select.Item value="1925">1925</Select.Item>
                <Select.Item value="1924">1924</Select.Item>
                <Select.Item value="1923">1923</Select.Item>
                <Select.Item value="1922">1922</Select.Item>
                <Select.Item value="1921">1921</Select.Item>
                <Select.Item value="1920">1920</Select.Item>
                <Select.Item value="1919">1919</Select.Item>
                <Select.Item value="1918">1918</Select.Item>
                <Select.Item value="1917">1917</Select.Item>
                <Select.Item value="1916">1916</Select.Item>
                <Select.Item value="1915">1915</Select.Item>
                <Select.Item value="1914">1914</Select.Item>
                <Select.Item value="1913">1913</Select.Item>
                <Select.Item value="1912">1912</Select.Item>
                <Select.Item value="1911">1911</Select.Item>
                <Select.Item value="1910">1910</Select.Item>
                <Select.Item value="1909">1909</Select.Item>
                <Select.Item value="1908">1908</Select.Item>
                <Select.Item value="1907">1907</Select.Item>
                <Select.Item value="1906">1906</Select.Item>
                <Select.Item value="1905">1905</Select.Item>
                <Select.Item value="1904">1904</Select.Item>
                <Select.Item value="1903">1903</Select.Item>
                <Select.Item value="1902">1902</Select.Item>
                <Select.Item value="1901">1901</Select.Item>
                <Select.Item value="1900">1900</Select.Item>
            </Select.Content>
        </Select.Root>
    </Flex>
</>
  )
}

export default YearComp