import React from 'react';
import logo from "./../assets/media/logonotext.png";
import { TextField, Text, Box,Grid,Button,Flex,Link } from '@radix-ui/themes';
import { FaHome, FaFileAlt, FaInfoCircle, FaBlog } from 'react-icons/fa';
import './navbar.css'


const Footer = () => {

  return (
    <div>
      <Grid columns={{ initial: '1', md: '2' }} gap="3" width="auto" mt="150px">
        <Box height="64px" m="40px">
          <div>
            <Text weight="bold" size="5" >
              Abonnez vous a notre newsletter :
            </Text>
          </div>
          <div style={{ marginTop: '15px' }}>
            <Text weight="regular" size="2" >
              Restez en courant sur les information des voyage et de nouveauté
            </Text>
          </div>
        </Box>
        <Box height="64px" m="40px">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <TextField.Root
              color = "gray"
              variant="soft"
              placeholder="Votre email"
              style={{ flexGrow: 1, marginRight: '20px', color: 'var(--gray-10)' }}
            />
            <Button  color = "gray"variant="soft" style={{ marginLeft: 'auto', color: 'var(--gray-10)' }}>
              s'abonner
            </Button>
          </div>
        </Box>
      </Grid>
      <Grid columns={{ initial: '1', md: '2' }} gap="3" width="auto">
        <Box m="20px" height="auto">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={logo}
              alt="Description of the image"
              style={{
                width: '20%',
                height: 'auto',
                objectFit: 'cover',
                borderRadius: 'var(--radius-3)',
                marginRight: '10px', // Add some space between the logo and the text
              }}
            />
            <Text weight="bold" size="4">Canada AVE</Text>
          </div>
          <div style={{margin:"20px"}}><Text>Ce site est une plateforme privée et indépendante des autorités permettant un accompagnement des personnes (physique ou morale) lors de leurs demandes administratives concernant les voyages, plus précisément les AVE Canada. Aucun lien avec les sites gouvernementaux du Canada. Les 43,92 € couvrent les frais nécessaires pour les services de l’immigration de 7 CAD (environ 5 €) ainsi que l’aide et l’assistance de ce site. Pour un traitement urgent, une option traitement prioritaire 24h peut être sélectionnée pour un coût supplémentaire de 15 €. Ces frais vous offrent une assistance pendant toute la durée de votre AVE, et vous permettent d’obtenir gratuitement un duplicata en cas de perte. De plus, Stripe est uniquement le moyen de paiement sécurisé permettant de rendre possible le paiement en ligne de manière ultra-sécurisée.
          </Text></div>
        </Box>
        <Box mt="170px" mb="200px" m="80px" height="64px">
          <Link href="/#">
            <Flex justify="center" align="bottom" mb="20px">
                <Button href="/#" size="4" variant="outline" style={{ width: '100%' }}>
                  <Flex align="center" justify="start">
                    <FaHome style={{ marginRight: '5px' }} /> {/* Add the home icon to the button */}
                    Acceuil
                  </Flex>
                </Button>
            </Flex>
          </Link>
          <Link href="/Formulaire">
            <Flex justify="center" align="center" mb="20px">
              <Button size="4" variant="outline" style={{ width: '100%' }}>
                <Flex align="center" justify="start">
                  <FaFileAlt style={{ marginRight: '5px' }} /> {/* Add the file alt icon to the button */}
                  AVE - Formulaire
                </Flex>
              </Button>
            </Flex>
          </Link>
          <Link href="/Contact">
            <Flex justify="center" align="center" mb="20px">
              <Button size="4" variant="outline" style={{ width: '100%' }}>
                <Flex align="center" justify="start">
                  <FaInfoCircle style={{ marginRight: '5px' }} /> {/* Add the info circle icon to the button */}
                  Information de voyage
                </Flex>
              </Button>
            </Flex>
          </Link>
          <Link href="/FAQ">
            <Flex justify="center" align="center">
              <Button size="4" variant="outline" style={{ width: '100%' }}>
                <Flex align="center" justify="start">
                  <FaBlog style={{ marginRight: '5px' }} /> {/* Add the blog icon to the button */}
                  Blog
                </Flex>
              </Button>
            </Flex>
          </Link>
        </Box>
      </Grid>
      <Flex m="20px" justify="center">
          <Text>
            Copyright © 2024 - 2025 Nom Entreprise, LLP Tous droits réservés
          </Text>
      </Flex>
    </div>
  );
};
export default Footer;