import React from 'react'
import {Box,Callout} from '@radix-ui/themes'
import { FaExclamationTriangle, } from 'react-icons/fa';

const Noneed = () => {
  return (
    <div>
      <Box ml="20px" mt="0px" mb="35px" mr="20px" height="auto">
        <Callout.Root>
            <Callout.Icon>
            <FaExclamationTriangle />
            </Callout.Icon>
            <Callout.Text>
            En tant que résident permanent légitime des États-Unis, vous n'avez pas besoin d'une autorisation de voyage électronique (AVE) pour voyager au Canada.
            </Callout.Text>
        </Callout.Root>
        </Box>
    </div>
  )
}

export default Noneed
