import React from 'react'
import {Flex,Text} from "@radix-ui/themes"
import DatePicker from './../DatePicker';

const InputDate = ({title,onDateChange}) => {
  const [date, setDate] = React.useState(false);

  function onSelectDate(date, dateString) {
    console.log(date, dateString);
    setDate(dateString);
    onDateChange(dateString);
  }
  return (
    <div><>
              <Text>{title}</Text>
              <Flex mt="12px" mb="12px" direction="column"> 
                <DatePicker onSelectDate={onSelectDate} />
              </Flex> 
            </>
    </div>
  )
}

export default InputDate