import React from 'react'
import {Flex,Text} from "@radix-ui/themes"

const ContactP = ({title}) => {
  return (
    <div>
        <Flex m="20px"><Text>{title}</Text></Flex>
    </div>
  )
}

export default ContactP