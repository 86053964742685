import { Container,Flex,Text } from '@radix-ui/themes'
import React from 'react'
import logo from './../assets/media/drapeau.png';
import FAQP from './FAQP';

const sayagz = () => {
  return (
    <Container>
        <Flex>
            <img src={logo} alt="Logo" style={{ width: 'auto', height: 'auto' }} />
            <div style={{margin:"100px"}}><Text color="red" size="9">FAQ sur l'ave</Text></div>
        </Flex>
        <FAQP Title="Les citoyens Français doivent-ils faire une demande d’AVE pour se rendre aux CANADA ?" paragraph="Oui, les citoyens français doivent obtenir une Autorisation de Voyage Électronique (AVE) avant de se rendre au Canada, s'ils sont dispensés de visa. L'AVE est une autorisation électronique qui permet aux ressortissants des pays dispensés de visa d'entrer au Canada pour des séjours de courte durée (moins de 6 mois) à des fins touristiques, commerciales ou de transit.

Pour obtenir une AVE, les citoyens français doivent remplir une demande en ligne sur le site Web du gouvernement canadien et payer des frais de traitement. La demande d'AVE peut généralement être traitée en quelques minutes, mais il est recommandé de faire la demande au moins quelques jours avant le départ prévu pour le Canada.

Il est important de noter que depuis le 7 septembre 2021, les voyageurs éligibles qui entrent au Canada doivent également présenter une preuve de vaccination COVID-19 complète en plus des documents d'entrée classiques tels que le passeport et l'AVE."/>
        <FAQP Title="Dois-je obtenir une nouvelle AVE pour chaque voyage aux CANADA ?" paragraph="Non, vous n'avez pas besoin d'obtenir une nouvelle Autorisation de Voyage Électronique (AVE) pour chaque voyage au Canada, tant que votre AVE actuelle est toujours valide et que vous remplissez toujours les conditions d'admissibilité.

L'AVE est généralement valide pour une période de cinq ans à partir de la date de délivrance ou jusqu'à l'expiration de votre passeport, selon la première éventualité. Vous pouvez utiliser votre AVE pour plusieurs voyages au Canada pendant cette période, tant que chaque voyage est de courte durée (moins de 6 mois) à des fins touristiques, commerciales ou de transit.

Il est important de noter que si vous avez obtenu une nouvelle nationalité, changé de nom ou de sexe depuis la délivrance de votre AVE, vous devrez peut-être en obtenir une nouvelle pour votre prochain voyage au Canada. Il est également recommandé de vérifier la validité de votre AVE avant chaque voyage et de demander une nouvelle si nécessaire."/>
        <FAQP Title="Combien de temps l’AVE est-elle valide, et combien de voyages puis-je effectuer pendant sa période de validité ?" paragraph="L'Autorisation de Voyage Électronique (AVE) est généralement valide pour une période de cinq ans à partir de la date de délivrance, ou jusqu'à l'expiration de votre passeport, selon la première éventualité. Pendant cette période de validité, vous pouvez effectuer autant de voyages que vous le souhaitez au Canada, à condition que chaque voyage soit de courte durée (moins de 6 mois) et à des fins touristiques, commerciales ou de transit.

Il est important de noter que si vous avez obtenu une nouvelle nationalité, changé de nom ou de sexe depuis la délivrance de votre AVE, vous devrez peut-être en obtenir une nouvelle pour votre prochain voyage au Canada. De plus, si votre passeport expire avant la fin de la période de validité de votre AVE, vous devrez demander une nouvelle AVE avec votre nouveau passeport.

Il est recommandé de vérifier la validité de votre AVE avant chaque voyage et de demander une nouvelle si nécessaire."/>
        <FAQP Title="Quelles sont les exigences relatives aux passeports dans le cadre du Programme d’exemption de visa ?" paragraph="Dans le cadre du Programme d'exemption de visa, les voyageurs doivent avoir un passeport valide pour être admissibles à l'entrée au Canada sans visa. Voici les exigences relatives aux passeports :

Le passeport doit être valide pendant toute la durée du séjour prévu au Canada.
Le passeport doit être un passeport émis par un pays participant au Programme d'exemption de visa.
Le passeport doit être un passeport biométrique ou électronique (e-passport). Les passeports lisibles à la machine (MRP) sont acceptables jusqu'au 30 septembre 2022.
Le passeport doit être un passeport individuel. Les passeports collectifs ou de groupe ne sont pas acceptables.
Il est important de noter que même si vous êtes admissible à entrer au Canada sans visa en vertu du Programme d'exemption de visa, vous devrez peut-être obtenir une Autorisation de Voyage Électronique (AVE) avant de voyager au Canada. Les citoyens de certains pays sont exemptés de l'exigence de l'AVE, mais il est important de vérifier si vous devez en obtenir une avant de voyager."/>

        <FAQP Title="Dois-je obtenir un AVE pour transiter par le CANADA ?" paragraph="Oui, si vous êtes un ressortissant étranger dispensé de visa et que vous transitez par le Canada vers une autre destination, vous devez obtenir une Autorisation de Voyage Électronique (AVE) avant de voyager. Cette exigence s'applique même si vous ne prévoyez pas de quitter l'aéroport et que vous restez dans la zone de transit pendant votre escale.

Il est important de noter que les citoyens de certains pays sont exemptés de l'exigence de l'AVE pour les transits. Par exemple, les citoyens américains et ceux disposant d'un visa canadien valide n'ont pas besoin d'AVE pour transiter par le Canada.

Il est recommandé de vérifier les exigences d'entrée du Canada pour les transit avant votre voyage pour vous assurer que vous avez les documents nécessaires."/>
    </Container>
  )
}

export default sayagz