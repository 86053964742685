
import { DatePicker, Space, ConfigProvider} from 'antd';

const onOk = (value) => {
  console.log('onOk: ', value);
};
const App = ({onSelectDate}) => (
  <ConfigProvider
    theme={{
      token: {
        controlItemBgHover: '#f0f0f0', // Change the background color on hover
        colorPrimary: '#dd3c3c', // Change the primary color
      },
    }}
  >
  <Space direction="vertical" size={12}>
    <DatePicker
      format={{
          format: 'YYYY-MM-DD',
          type: 'mask',
        }}
      showTime
      onChange={onSelectDate}
      onOk={onOk}
    />
  </Space>
</ConfigProvider>
);
export default App;