import { useRef } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import "./navbar.css";
import logo from './../assets/media/logo1.png'; // Import the logo image

function Navbar() {
	const navRef = useRef();
	const navigate = useNavigate();

	const showNavbar = () => {
		navRef.current.classList.toggle(
			"responsive_nav"
		);
	};

	const handleLogoClick = () => {
		navigate("/");
	};

	return (
		<header>
			<img
				src={logo}
				alt="Logo"
				style={{ width: '100px', height: 'auto', cursor: 'pointer' }}
				onClick={handleLogoClick}
			/>
			<nav ref={navRef}>
				<a href="/Formulaire">AVE - formulaire</a>
				<a href="/Contact">Information de voyage</a>
				<a href="/FAQ">FAQ</a>
				<button
					className="nav-btn nav-close-btn"
					onClick={showNavbar}>
					<FaTimes />
				</button>
			</nav>
			<button
				className="nav-btn"
				onClick={showNavbar}>
				<FaBars />
			</button>
		</header>
	);
}

export default Navbar;
