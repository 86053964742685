import React from 'react'
import {Select,Text,Flex} from "@radix-ui/themes"

const SelectCountry = ({handleCountrySelect1,country1,handleCountrySelect2,country2}) => {
  return (
    <>
        <Text>Veuillez choisir le code du pays existant sur votre passport ? *</Text>
        <Flex mt="35px" mb="12px" direction="column" maxWidth="240px">
        
        <Select.Root defaultValue=""  onValueChange={handleCountrySelect1} value={country1}>
            <Select.Trigger placeholder="Veuillez sélectionner" variant="surface" />
            <Select.Content>
            <Select.Item value="D">D (Allemagne, République fédérale)</Select.Item>
            <Select.Item value="AND">AND (Andorre)</Select.Item>
            <Select.Item value="AUS">AUS (Australie)</Select.Item>
            <Select.Item value="AUT">AUT (Autriche)</Select.Item>
            <Select.Item value="BHS">BHS (Bahamas)</Select.Item>
            <Select.Item value="BRB">BRB (Barbade)</Select.Item>
            <Select.Item value="BEL">BEL (Belgique)</Select.Item>
            <Select.Item value="BRN">BRN (Brunei Darussalam)</Select.Item>
            <Select.Item value="BGR">BGR (Bulgarie)</Select.Item>
            <Select.Item value="CHL">CHL (Chili)</Select.Item>
            <Select.Item value="CYP">CYP (Chypre)</Select.Item>
            <Select.Item value="KOR">KOR (Corée du Sud)</Select.Item>
            <Select.Item value="HRV">HRV (Croatie)</Select.Item>
            <Select.Item value="DNK">DNK (Danemark)</Select.Item>
            <Select.Item value="ARE">ARE (Émirats arabes unis)</Select.Item>
            <Select.Item value="ESP">ESP (Espagne)</Select.Item>
            <Select.Item value="EST">EST (Estonie)</Select.Item>
            <Select.Item value="FIN">FIN (Finlande)</Select.Item>
            <Select.Item value="FRA">FRA (France)</Select.Item>
            <Select.Item value="GRC">GRC (Grèce)</Select.Item>
            <Select.Item value="HUN">HUN (Hongrie)</Select.Item>
            <Select.Item value="SLB">SLB (Îles Salomon)</Select.Item>
            <Select.Item value="IRL">IRL (Irlande)</Select.Item>
            <Select.Item value="ISL">ISL (Islande)</Select.Item>
            <Select.Item value="ITA">ITA (Italie)</Select.Item>
            <Select.Item value="JPN">JPN (Japon)</Select.Item>
            <Select.Item value="LVA">LVA (Lettonie)</Select.Item>
            <Select.Item value="LIE">LIE (Liechtenstein)</Select.Item>
            <Select.Item value="LTU">LTU (Lituanie)</Select.Item>
            <Select.Item value="LUX">LUX (Luxembourg)</Select.Item>
            <Select.Item value="MLT">MLT (Malte)</Select.Item>
            <Select.Item value="MCO">MCO (Monaco)</Select.Item>
            <Select.Item value="NOR">NOR (Norvège)</Select.Item>
            <Select.Item value="NZL">NZL (Nouvelle-Zélande)</Select.Item>
            <Select.Item value="PNG">PNG (Papouasie-Nouvelle-Guinée)</Select.Item>
            <Select.Item value="NLD">NLD (Pays-Bas)</Select.Item>
            <Select.Item value="POL">POL (Pologne)</Select.Item>
            <Select.Item value="PRT">PRT (Portugal)</Select.Item>
            <Select.Item value="CZE">CZE (République tchèque)</Select.Item>
            <Select.Item value="ROU">ROU (Roumanie)</Select.Item>
            <Select.Item value="GBD">GBD (R.-U. Terr. brit. outre-mer)</Select.Item>
            <Select.Item value="GBN">GBN (R.-U. - Ress. brit. outre-mer)</Select.Item>
            <Select.Item value="GBO">GBO (R.-U. - Cit. brit. d'outre-mer)</Select.Item>
            <Select.Item value="GBR">GBR (R.-U. - Citoyen britannique)</Select.Item>
            <Select.Item value="GBS">GBS (R.-U. - Sujet britannique)</Select.Item>
            <Select.Item value="SMR">SMR (Saint-Marin)</Select.Item>
            <Select.Item value="WSM">WSM (Samoa)</Select.Item>
            <Select.Item value="SGP">SGP (Singapour)</Select.Item>
            <Select.Item value="SVK">SVK (Slovaquie)</Select.Item>
            <Select.Item value="SVN">SVN (Slovénie)</Select.Item>
            <Select.Item value="SWE">SWE (Suède)</Select.Item>
            <Select.Item value="CHE">CHE (Suisse)</Select.Item>
            <Select.Item value="ANG">ANG (Anguilla)</Select.Item>
            <Select.Item value="BMU">BMU (Bermudes)</Select.Item>
            <Select.Item value="TCA">TCA (Îles Turks et Caïques)</Select.Item>
            <Select.Item value="CYM">CYM (Îles Caïmans)</Select.Item>
            <Select.Item value="MSR">MSR (Montserrat)</Select.Item>
            <Select.Item value="VGB">VGB (Îles Vierges britanniques)</Select.Item>
            <Select.Item value="SHN">SHN (Sainte-Hélène)</Select.Item>
            </Select.Content>
        </Select.Root>
        </Flex>
        <Text>Veuillez choisir le code du pays existant sur votre passport ? *</Text>
        <Flex mt="35px" mb="12px" direction="column" maxWidth="240px">
        <Select.Root defaultValue=""  onValueChange={handleCountrySelect2} value={country2}>
            <Select.Trigger placeholder="Veuillez sélectionner" variant="surface" />
            <Select.Content>
            <Select.Item value="D">Allemagne, Rép Fédérale</Select.Item>
            <Select.Item value="AND">Andorre</Select.Item>
            <Select.Item value="AUS">Australie</Select.Item>
            <Select.Item value="AUT">Autriche</Select.Item>
            <Select.Item value="BHS">Bahamas</Select.Item>
            <Select.Item value="BRB">Barbade</Select.Item>
            <Select.Item value="BEL">Belgique</Select.Item>
            <Select.Item value="BRN">Brunéi Darussalam</Select.Item>
            <Select.Item value="BGR">Bulgarie</Select.Item>
            <Select.Item value="CHL">Chili</Select.Item>
            <Select.Item value="CYP">Chypre</Select.Item>
            <Select.Item value="KOR">Corée, Sud</Select.Item>
            <Select.Item value="HRV">Croatie</Select.Item>
            <Select.Item value="DNK">Danemark</Select.Item>
            <Select.Item value="ARE">Émirats arabes unis</Select.Item>
            <Select.Item value="ESP">Espagne</Select.Item>
            <Select.Item value="EST">Estonie</Select.Item>
            <Select.Item value="FIN">Finlande</Select.Item>
            <Select.Item value="FRA">France</Select.Item>
            <Select.Item value="GRC">Grèce</Select.Item>
            <Select.Item value="HUN">Hongrie</Select.Item>
            <Select.Item value="SLB">Îles Salomon</Select.Item>
            <Select.Item value="IRL">Irlande</Select.Item>
            <Select.Item value="ISL">Islande</Select.Item>
            <Select.Item value="ITA">Italie</Select.Item>
            <Select.Item value="JPN">Japon</Select.Item>
            <Select.Item value="LVA">Lettonie</Select.Item>
            <Select.Item value="LIE">Liechtenstein</Select.Item>
            <Select.Item value="LTU">Lituanie</Select.Item>
            <Select.Item value="LUX">Luxembourg</Select.Item>
            <Select.Item value="MLT">Malte</Select.Item>
            <Select.Item value="MEX">Mexique</Select.Item>
            <Select.Item value="MCO">Monaco</Select.Item>
            <Select.Item value="NOR">Norvège</Select.Item>
            <Select.Item value="NZL">Nouvelle-Zélande</Select.Item>
            <Select.Item value="PNG">Papouasie-Nouvelle-Guinée</Select.Item>
            <Select.Item value="NLD">Pays-Bas, Les</Select.Item>
            <Select.Item value="POL">Pologne</Select.Item>
            <Select.Item value="PRT">Portugal</Select.Item>
            <Select.Item value="CZE">Tchèque, République</Select.Item>
            <Select.Item value="ROU">Roumanie</Select.Item>
            <Select.Item value="GBD">R.-U. Terr. brit. outre-mer</Select.Item>
            <Select.Item value="GBN">R.-U. - Ress. brit. outre-mer</Select.Item>
            <Select.Item value="GBO">R.-U. - Cit. brit. d'outre-mer</Select.Item>
            <Select.Item value="GBR">R.-U. - Citoyen britannique</Select.Item>
            <Select.Item value="SMR">Saint-Marin</Select.Item>
            <Select.Item value="WSM">Samoa</Select.Item>
            <Select.Item value="SGP">Singapour</Select.Item>
            <Select.Item value="SVK">Slovaquie</Select.Item>
            <Select.Item value="SVN">Slovénie</Select.Item>
            <Select.Item value="SWE">Suède</Select.Item>
            <Select.Item value="CHE">Suisse</Select.Item>
            <Select.Item value="ANG">Anguilla</Select.Item>
            <Select.Item value="BMU">Bermudes</Select.Item>
            <Select.Item value="TCA">Îles Turks et Caïques</Select.Item>
            <Select.Item value="CYM">Caïmans, Îles</Select.Item>
            <Select.Item value="MSR">Montserrat</Select.Item>
            <Select.Item value="VGB">Îles Vierges britanniques</Select.Item>
            <Select.Item value="SHN">Sainte-Hélène</Select.Item>
            </Select.Content>
        </Select.Root>
        </Flex>
    </>
  )
}

export default SelectCountry
