import { useState, useEffect } from 'react';
import { Container, Text, Grid, Card, Box, Select, Flex, Radio, Button, TextArea, Checkbox, Link } from '@radix-ui/themes';
import YearComp from './YearComp';
import './Step4.css';
import Passtype from './Step4Comp/Passtype';
import NotAllowed from './Step4Comp/NotAllowed';
import SelectCountry from './Step4Comp/SelectCountry';
import Forbiden from './Step4Comp/Forbiden';
import CitizenCheck from './Step4Comp/CitizenCheck';
import Noneed from './Step4Comp/Noneed';
import Notpossible from './Step4Comp/Notpossible';
import InputP from './Step4Comp/InputP';
import InputDate from './Step4Comp/InputDate';
import CountryP from './Step4Comp/CountryP';
import CityInput from './Step4Comp/CityInput';
import SexPicker from './Step4Comp/SexPicker';
import MariState from './Step4Comp/MariState';
import VisaPast from './Step4Comp/VisaPast';
import IdInput from './Step4Comp/IdInput';
import Occupation from './Step4Comp/Occupation';
import JobType from './Step4Comp/JobType';
import Languague from './Step4Comp/Languague';
import NextDate from './Step4Comp/NextDate';
import DateDetail from './Step4Comp/DateDetail';
import Refused from './Step4Comp/Refused';

const Step4 = ({ onFormValuesChange , onValidation}) => {
  const [isCitizen, setIsCitizen] = useState(null);
  const [oldUser, setOldUser] = useState(null);
  const [Vacay, setVacay] = useState(null);
  const [Radio4, setRadio4] = useState(null);
  const [Radio5, setRadio5] = useState(null);
  const [Radio6, setRadio6] = useState(null);
  const [Radio7, setRadio7] = useState(null);
  const [country1, setCountry1] = useState("");
  const [country2, setCountry2] = useState("");
  const [country3, setCountry3] = useState("");
  const [country4, setCountry4] = useState("");
  const [birth, setbirth] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [formValues, setFormValues] = useState({
    DocumentType: '',
    PassCountryCode:'',
    PassNationality:'',
    Nom:'',
    Prenom:'',
    PassBirthCountry: '',
    PassNumero:'',
    Birthdate:null,
    Passexpiration:null,
    PassDelivery:null,
    BirthCity:'',
    sex:'',
    LocalCountry:'',
    MartialStatus:'',
    PastVisa:'',
    IdolVisa:'',
    Occupation:'',
    JobCity:'',
    JobYearStart:'',
    JobCountry:'',
    EmployerorUniversity:'',
    JobTitle:'',
    email:'',
    Phone:'',
    Language:'',
    AptNumber:'',
    AdressDetails:'',
    CodePostal:'',
    HomeCountry:'',
    HouseNumber:'',
    AdressDetails2:'',
    HomeCity:'',
    KnowWhen:'',
    TravelDate:'',
    RefusedBefore:'',
    RefusedBeforeDescription:'',
    additionalInfo:'',
    CrimePast:'',
    Tuberculose:'',
  });

  useEffect(() => {
    if (oldUser === "2") {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [oldUser]);

  const handleRadioChange = (value) => {
    setIsCitizen(value);
  };

  const handleRadioChange2 = (value) => {
    setOldUser(value);
    setFormValues(prevValues => ({
      ...prevValues,
      PastVisa: value === '1' ? true : false,
    }));
  };

  const handleRadioChange3 = (value) => {
    setVacay(value);
    setFormValues(prevValues => ({
      ...prevValues,
      KnowWhen: value === '1' ? true : false,
    }));
  };

  const handleRadioChange4 = (value) => {
    setRadio4(value);
    setFormValues(prevValues => ({
      ...prevValues,
      RefusedBefore: value === '1' ? true : false,
    }));
  };

  const handleRadioChange5 = (value) => {
    setRadio5(value);
    setFormValues(prevValues => ({
      ...prevValues,
      CrimePast : value === '1' ? true : false,
    }));
  };

  const handleRadioChange6 = (value) => {
    setRadio6(value);
    setFormValues(prevValues => ({
      ...prevValues,
      Tuberculose : value === '1' ? true : false,
    }));
  };

  const handleRadioChange7 = (value) => {
    setRadio7(value);
  };

  const [selectedValue, setSelectedValue] = useState('');
  const [selectedValue2, setSelectedValue2] = useState('');

  const occupationMapping = {
    "0": "Affaires, finance et administration",
    "1": "Arts, culture, sports et loisirs",
    "2": "Chômeur",
    "3": "Emplois dans les ventes et les services",
    "4": "Enseignement, droit et services sociaux, communautaires et gouvernementaux",
    "5": "Étudiant",
    "6": "Fabrication et services d'utilité publique",
    "7": "Forces armées",
    "8": "Gestion",
    "9": "Métiers, transport, machinerie et domaines apparentés",
    "10": "Personne au foyer",
    "11": "Ressources naturelles, agriculture et production connexe",
    "12": "Retraité",
    "13": "Sciences naturelles et appliquées et domaines apparentés",
    "14": "Secteur de la santé",
  };

  const handleSelectChange2 = (value) => {
    const textValue = occupationMapping[value];
    setSelectedValue2(value);
    setFormValues((prevValues) => ({
      ...prevValues,
      Occupation: textValue,
    }));
  };

  const handleSelectChange = (value) => {
    setSelectedValue(value);
    setFormValues((prevValues) => ({
      ...prevValues,
      DocumentType: value,
    }));
  };

  const handleCountrySelect1 = (value) => {
    setCountry1(value);
    setFormValues((prevValues) => ({
      ...prevValues,
      PassCountryCode: value,
    }));
  };

  const handleCountrySelect3 = (value) => {
    setCountry3(value);
    setFormValues((prevValues) => ({
      ...prevValues,
      PassBirthCountry: value,
    }));
  };

  const handleCountrySelect4 = (value) => {
    setCountry4(value);
    setFormValues((prevValues) => ({
      ...prevValues,
      LocalCountry: value,
    }));
  };

  const handleSubmit = () => {
    console.log("Form Data:", formValues);
    onFormValuesChange(formValues);
    onValidation(true);
  };

  const handleInputChange = (name, value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleCountrySelect2 = (value) => {
    setCountry2(value);
    setFormValues((prevValues) => ({
      ...prevValues,
      PassNationality: value,
    }));
  };

  const handleBirthdateChange = (e) => {
    setbirth(e);
    setFormValues(prevValues => ({
      ...prevValues,
      Birthdate: e,
    }));
  };

  const handleexpiryChange = (e) => {
    setbirth(e);
    setFormValues(prevValues => ({
      ...prevValues,
      Passexpiration: e,
    }));
  };

  const handledeliveryChange = (e) => {
    setbirth(e);
    setFormValues(prevValues => ({
      ...prevValues,
      PassDelivery: e,
    }));
  };

  const handleTravelDate = (e) => {
    setbirth(e);
    setFormValues(prevValues => ({
      ...prevValues,
      TravelDate: e,
    }));
  };

  const handleSexChange = (value) => {
    setFormValues(prevValues => ({
      ...prevValues,
      sex: value,
    }));
  };

  const handleLanguageChange = (value) => {
    setFormValues(prevValues => ({
      ...prevValues,
      Language: value,
    }));
  };

  const handleMartialStatus = (value) => {
    setFormValues(prevValues => ({
      ...prevValues,
      MartialStatus: value,
    }));
  };

  const handleTextAreaChange = (e) => {
    setFormValues({
      ...formValues,
      additionalInfo: e.target.value,
    });
  };

  useEffect(() => {
    if (isChecked) {
      handleSubmit();
      // Reset the checkbox state to ensure the function runs only once
      setIsChecked(false);
    }
  }, [isChecked]);


  return (
    <Container size="2" >
        <Card m="20px"style={{boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",}}>
          
          <div style={{ margin: "20px" }}>
            <Text size="6">Informations Général du Passport</Text>
          </div>
          <Grid columns={{ initial: '1', md: '2' }} gap="3" width="auto">
            <Box ml="20px" mt="20px" mb="35px" height="auto">
              <Passtype selectedValue={selectedValue} handleSelectChange={handleSelectChange}/>
              {(selectedValue === "0" || selectedValue === "1" || selectedValue === "2" || selectedValue === "3" || selectedValue === "4") &&
              (<NotAllowed />)}
            </Box>
            <Box ml="20px" mt="20px" mb="35px" mr="20px" height="auto">
              {selectedValue === '5' && (
                <Forbiden/>
              )}

              {(selectedValue === "0" || selectedValue === "1" || selectedValue === "2" || selectedValue === "3" || selectedValue === "4") && (
                <SelectCountry handleCountrySelect1={handleCountrySelect1} country1={country1} handleCountrySelect2={handleCountrySelect2} country2={country2}/>
              )}

              {(selectedValue === "6" || selectedValue === "7") && (
                <CitizenCheck handleRadioChange={handleRadioChange}/>
              )}

            </Box>
          </Grid>
          {isCitizen === "1" && (selectedValue === "6" || selectedValue === "7") && (
              <Noneed/>
            )}

            {isCitizen === "2" && (selectedValue === "6" || selectedValue === "7") && (
              <Notpossible/>

            
            
      )}
          {/* {country1 && country2 && (  */}
          <>
          <Flex m="20px"><Text size="6">Informations indiquées sur le passeport du demandeur</Text></Flex>
          <Grid columns={{ initial: '1', md: '2' }} gap="3" width="auto">
          
          <Box ml="20px" mt="20px" mb="35px" height="auto">
          <InputP
            title="Nom *"
            value={formValues.Nom}
            onChange={(value) => handleInputChange('Nom', value)}
          />
          <InputP
            title="Numéro de passport *"
            value={formValues.PassNumero}
            onChange={(value) => handleInputChange('PassNumero', value)}
          />
            {/* Date de Naissance* */}
          <InputDate title="Date de Naissance*" onDateChange={handleBirthdateChange}/>
          <InputDate title="Date de Délivrance du Passport *" onDateChange={handledeliveryChange} />
            
          </Box>
          <Box ml="20px" mt="20px" mb="35px" height="auto">
            <InputP title="Prénom *" value={formValues.Prenom}
            onChange={(value) => handleInputChange('Prenom', value)}/>
            <CountryP title="de naissance" value={country3} setfunction={handleCountrySelect3}/>
            <InputDate title="Date d'expiration du Passport *" onDateChange={handleexpiryChange}/>
          </Box>
          </Grid>
          <CityInput value={formValues.BirthCity}
            onChange={(value) => handleInputChange('BirthCity', value)}/>
            <>
  <SexPicker handleSexChange={handleSexChange}/>
</>

        </>
        <>
          <Flex m="20px"><Text size="6">Informations personnelles du demandeur</Text></Flex>
          <Grid columns={{ initial: '1', md: '2' }} gap="3" width="auto">
          
          <Box ml="20px" mt="20px" mb="35px" height="auto">
          
                <CountryP title="" value={country4} setfunction={handleCountrySelect4}/>
                <MariState value={formValues.MartialStatus} setfunction={handleMartialStatus}/>
          </Box>
          <Box ml="20px" mt="20px" mb="35px" height="auto">
          <>
              <VisaPast handleRadioChange2={handleRadioChange2}/>
          </>
          {oldUser === "2" && (
        <IdInput isVisible={isVisible} value={formValues.IdoldVisa} onChange={(value) => handleInputChange('IdolVisa', value)}/>
      )}
</Box>
          </Grid>
</>
        <>
          <Flex m="20px"><Text size="6">Informations sur l'emploi</Text></Flex>
          <Grid columns={{ initial: '1', md: '2' }} gap="3" width="auto">
          
          <Box ml="20px" mt="45px" mb="35px" height="auto">
          <>
          <Occupation handleSelectChange2={handleSelectChange2} selectedValue2={selectedValue2}/>
          </>
          <>
          {selectedValue2 !== "2" && selectedValue2 !== "10" && selectedValue2 !== "12" &&  (
          <>
          <InputP title="Ville*" value={formValues.JobCity} onChange={(value) => handleInputChange('JobCity', value)}/>
          <Text>Depuis quelle année ?</Text>
          <YearComp value={formValues.JobYearStart} setfunction={(value) => handleInputChange('JobYearStart', value)} />
          </>
)}
          </>
          
</Box>
          <Box ml="20px" mt="20px" mb="35px" height="auto">
          <>
            {selectedValue2 !== "2" && selectedValue2 !== "10" && selectedValue2 !== "12" &&  (
          <>
          {/* <Text>Nom de l'employeur ou de l'école/université : *</Text> */}
          <InputP title="Nom de l'employeur ou de l'école/université : *" value={formValues.EmployerorUniversity} onChange={(value) => handleInputChange('EmployerorUniversity', value)}/>
          <CountryP title="" value={formValues.JobCountry} setfunction={(value) => handleInputChange('JobCountry', value)}/>
          <JobType selectedValue2={selectedValue2} value={formValues.JobTitle} setfunction={(value) => handleInputChange('JobTitle', value)}/>
          </>
)}
</>
</Box>
          </Grid>
        </>
        <>
        <Flex m="20px"><Text size="6">Coordonnées</Text></Flex>
          <Grid columns={{ initial: '1', md: '2' }} gap="3" width="auto">
          <Box ml="20px" mt="20px" mb="35px" height="auto">
            <InputP title="Adresse électronique*" value={formValues.email} onChange={(value) => handleInputChange('email', value)}/>
            <InputP title="Téléphone mobile*" value={formValues.Phone} onChange={(value) => handleInputChange('Phone', value)}/>
</Box>
          <Box ml="20px" mt="20px" mb="35px" height="auto">
            <Languague handleLanguageChange={handleLanguageChange}/>
            </Box></Grid>

        </>
        <>
        <Flex m="20px"><Text size="6">Adresse du domicile</Text></Flex>
          <Grid columns={{ initial: '1', md: '2' }} gap="3" width="auto">
          <Box ml="20px" mt="20px" mb="35px" height="auto">
            <InputP title="Numéro d'appartement ou d'unité (Si Il ya lieu)" value={formValues.AptNumber} onChange={(value) => handleInputChange('AptNumber', value)}/>
            <InputP title="Adresse de voirie ou nom de rue *" value={formValues.AdressDetails} onChange={(value) => handleInputChange('AdressDetails', value)}/>
            <CountryP value={formValues.HomeCountry} setfunction={(value) => handleInputChange('HomeCountry', value)}/>
            <InputP title="Code Postal" value={formValues.CodePostal} onChange={(value) => handleInputChange('CodePostal', value)}/>
</Box>
          <Box ml="20px" mt="20px" mb="35px" height="auto">
            
            <InputP title="Numéro de voirie ou d'immeuble ou nom de la maison *"value={formValues.HouseNumber} onChange={(value) => handleInputChange('HouseNumber', value)}/>
            <InputP title="Adresse de voirie ou nom de rue 2 (Si Il ya lieu)" value={formValues.AdressDetails2} onChange={(value) => handleInputChange('AdressDetails2', value)}/>
            <InputP title="Ville" value={formValues.HomeCity} onChange={(value) => handleInputChange('HomeCity', value)}/>
</Box>
            

</Grid>

</>
          <>
            <Flex m="20px"><Text size="6">Renseignement sur le voyage</Text></Flex>
            <Box style={{ alignItems:"center", background: 'var(--gray-a2)', borderRadius: 'var(--radius-3)' }}>
              <Container p="20px" size="1">
                <NextDate handleRadioChange3={handleRadioChange3}/>
                {Vacay === "1" && (
                  <DateDetail onSelectDate={handleTravelDate}/>
                )}
              </Container>
            </Box>
          </>
          <>
            <Flex m="20px"><Text size="6">Questions sur les antécédents</Text></Flex>
            <Box style={{ alignItems:"center", background: 'var(--gray-a2)', borderRadius: 'var(--radius-3)' }}>
              <Container p="20px" size="1">
                <Refused handleRadioChange={handleRadioChange4} name="refused" title="Vous a-t-on déjà refusé un visa ou un permis, interdit l'entrée au Canada ou dans tout autre pays ou territoire ou demandé de quitter le Canada ou tout autre pays ou territoire? *"/>
{Radio4 === "1" && (
    <>              <Text>Pour chaque refus, veuillez indiquer le pays qui vous a refusé un visa ou un permis, ou encore l'entrée, de même que les raisons qui vous ont été fournies. *</Text>
                    <Flex mt="20px" mb="20px" direction="column" gap="3" maxWidth="500px">
                    <TextArea color="red" variant="surface" placeholder="Veuillez clarifier en quelques lignes" />
                    </Flex>
      
    </>
  )}
                  {/* <Text>Avez-vous déjà commis une infraction criminelle dans tout pays ou territoire, ou vous a-t-on déjà arrêté pour une telle infraction, accusé d'une telle infraction ou reconnu coupable d'une telle infraction? *</Text> */}
                  <Refused handleRadioChange={handleRadioChange5} name="crime" title="Avez-vous déjà commis une infraction criminelle dans tout pays ou territoire, ou vous a-t-on déjà arrêté pour une telle infraction, accusé d'une telle infraction ou reconnu coupable d'une telle infraction? *"/>
{Radio5 === "1" && (
    <>              <Text>Pour chaque arrestation, accusation ou déclaration de culpabilité, veuillez indiquer l'endroit (ville, pays), la date (mois et année), la nature de l'infraction et la peine infligée. *</Text>
                    <Flex mt="20px" mb="20px" direction="column" gap="3" maxWidth="500px">
                    <TextArea color="red" variant="surface" placeholder="Veuillez clarifier en quelques lignes" />
                    </Flex>
      
    </>
  )}
                    <Refused handleRadioChange={handleRadioChange6} name="when" title="Au cours des deux dernières années, vous a-t-on diagnostiqué une tuberculose ou avez-vous été en contact avec une personne ayant la tuberculose? *"/>
{Radio6 === "1" && (
    <>              <Text>Est-ce que c'est votre emploi comme travailleur de la santé qui vous a mis en contact avec des gens atteints de la tuberculose? *</Text>
                    <Flex ml="20px" mb="25px" mt="12px" asChild gap="2" alignItems="center">
                    <Text as="label" size="2">
                        <Radio
                          size="1"
                          name="jobrelated"
                          value="1"
                          onValueChange={() => handleRadioChange7("1")}
                        />
                        Oui
                        <Radio
                          size="1"
                          name="jobrelated"
                          value="2"
                          onValueChange={() => handleRadioChange7("2")}
                        />
                        Non
                      </Text>
                    </Flex>
{Radio7 === "1" && (
    <>              <Text>Vous a-t-on déjà diagnostiqué une tuberculose ? *</Text>
                    <Flex ml="20px" mb="25px" mt="12px" asChild gap="2" alignItems="center">
                    <Text as="label" size="2">
                        <Radio
                          size="1"
                          name="disease"
                          value="1"
                          
                        />
                        Oui
                        <Radio
                          size="1"
                          name="disease"
                          value="2"
                          
                        />
                        Non
                      </Text>
                    </Flex>
      
    </>
  )}
      
    </>
  )}
                    <Text>Avez-vous un des troubles suivants *</Text>
                    <Flex direction="column" maxWidth="500px" mt="20px" mb="20px">
                      <Select.Root >
                        <Select.Trigger>
                        </Select.Trigger>
                        <Select.Content position="popper">
                          <Select.Item value="Syphilis non traitée">Syphilis non traitée</Select.Item>
                          <Select.Item value="Alcoolisme ou toxicomanie non traités">Alcoolisme ou toxicomanie non traités</Select.Item>
                          <Select.Item value="Problème de santé mentale non traité accompagné d'une psychose">Problème de santé mentale non traité accompagné d'une psychose</Select.Item>
                          <Select.Item value="Aucun des troubles ci-dessus">Aucun des troubles ci-dessus</Select.Item>

                        </Select.Content>
                      </Select.Root>
                    </Flex>
                    <Text>Veuillez indiquer brièvement tout autre détail pertinent à votre demande. Par exemple, la nécessité de voyager de toute urgence au Canada. Fournissez les détails pertinents pour éviter tout retard dans le traitement de votre demande. (facultative)</Text>
                    <Flex mt="20px" direction="column" gap="3" maxWidth="500px">
                      <TextArea color="red" variant="surface" placeholder="Veuillez fournir au moins deux lignes d'explications." value={formValues.additionalInfo} onChange={handleTextAreaChange}/>
                    </Flex>

                  
              </Container>
        
            </Box>

            <Flex m="20px"><Text size="6">Consentement et déclaration</Text></Flex>
            <Box p="20px" style={{ alignItems:"center", borderRadius: 'var(--radius-3)' }}>
              <Flex m="20px"><Text weight="regular">Les renseignements que vous fournissez à IRCC sont recueillis en vertu de la Loi sur l'immigration et la protection des réfugiés (LIPR) afin de déterminer l'admissibilité au Canada. Ces renseignements peuvent être transmis à d'autres institutions fédérales, y compris mais sans s'y restreindre, l'Agence des services frontaliers du Canada (ASFC), la Gendarmerie royale du Canada (GRC), le Service canadien du renseignement de sécurité (SCRS), le ministère des Affaires étrangères, Commerce et Développement Canada (MAECDC), Emploi et Développement social Canada (EDSC), l'Agence du revenu du Canada (ARC), à des gouvernements provinciaux et territoriaux et à des gouvernements étrangers, conformément au paragraphe 8(2) de la Loi sur la protection des renseignements personnels. Aux fins de l'administration et de l'application de la législation sur l'immigration, et à condition que cela ne risque pas de mettre en danger le demandeur et sa famille, il se peut que ces renseignements soient communiqués à des gouvernements étrangers, à des organismes d'exécution de la loi et à des autorités habilitées à retenir, institutions auxquelles on pourrait demander d'authentifier ces renseignements. Ceux-ci peuvent aussi être systématiquement validés par d'autres institutions gouvernementales canadiennes afin d'authentifier le statut et l'identité aux fins d'administration de leurs programmes.
              </Text></Flex>
              <Flex m="20px"><Text weight="regular">Si vous fournissez vos données biométriques dans le cadre de votre demande, les empreintes digitales recueillies seront stockées et transmises à la GRC. Ces données peuvent également être communiquées à d'autres organismes d'exécution de la loi conformément au paragraphe 13.11(1) du Règlement sur l'immigration et la protection des réfugiés. Les renseignements pourraient être utilisés pour l'établissement ou la vérification de l'identité d'une personne dans le but de prévenir, enquêter ou intenter des poursuites pour une infraction décrite dans toute loi du Canada ou d'une province.</Text></Flex>
              <Flex m="20px"><Text weight="regular">Les renseignements pourraient également être utilisés pour l'établissement ou la vérification de l'identité d'une personne dont il est impossible d'établir ou de vérifier raisonnablement d'une autre manière l'identité à cause d'un trouble physique ou mental. Le Canada pourrait également partager de l'information sur l'immigration lié aux données biométriques avec des gouvernements étrangers avec lesquels le Canada a une entente ou un accord.</Text></Flex>
              <Flex m="20px"><Text weight="regular">Selon le type de demande présentée, les renseignements fournis seront conservés dans un ou plusieurs fichiers de renseignements personnels (FRP) conformément à l'article 10(1) de la Loi sur la protection des renseignements personnels. Toute personne a également le droit à la protection de ses renseignements personnels conservés dans chaque FRP correspondant, et a le droit d'y accéder, en vertu de la Loi sur l'accès à l'information. Pour en savoir plus sur les FRP ayant trait aux secteurs d'activité et services d'IRCC et aux programmes d'accès à l'information et de protection des renseignements personnels du gouvernement du Canada, consultez le site Web Infosource ou communiquez avec le Télécentre d'IRCC. Info Source est également à votre disposition dans les bibliothèques publiques partout au Canada.</Text></Flex>
              <Flex m="20px"><Text size="5">Déclaration du demandeur :</Text></Flex>
              <Flex m="20px"><Text size="5">J'accepte *</Text></Flex>
              <Flex m="20px"><Text>J'ai lu et compris la déclaration ci-haut.</Text></Flex>
              <Flex m="20px"><Text>Je déclare que les renseignements que j'ai fournis dans cette demande sont véridiques, complets et exacts.</Text></Flex>
              <Flex m="20px"><Text>Je sais qu'une fausse déclaration constitue une infraction à l'article 127 de la Loi sur l'immigration et la protection des réfugiés et peut conduire à une interdiction de territoire au Canada ou à un renvoi du Canada.</Text></Flex>
              <Flex direction="column" gap="3">
                <Text as="label" size="3">
                  <Flex as="span" gap="2">
                  <Checkbox size="2" /> J'accepte
                  </Flex>
                </Text>

                <Text as="label" size="3">
                  
                    <Checkbox size="2" /> Je confirme avoir, lu, compris et accepter<Link href="#"> les Conditions Générales d’Utilisation et de Service</Link>
                    
                </Text>
              </Flex>
              <Flex m="20px">
                  <Button onClick={handleSubmit}>je confirme et je souscris mes informations</Button>
              </Flex>
              
</Box>
            
          </>






      {/* )} */}
        </Card>
    </Container>
  )
}

export default Step4