import React, { useState, useEffect } from 'react';
import { Container, Text, Grid, Card, Box, Select, Flex, TextField, Radio, Checkbox } from '@radix-ui/themes';

const Step3 = ({ formValues, selectedValue, onInputChange, onSelectChange, onCheckboxChange, onValidationChange }) => {

  const handleSelectChange = (value) => {
    onSelectChange(value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    onInputChange(name, value);
  };

  const handleCheckboxChange = (name, checked) => {
    onCheckboxChange(name, checked);
  };

  const validateForm = () => {
    const {
      name,
      addressNumber,
      country,
      phoneNumber,
      email,
      companyName,
      memberId,
      province,
      firstName,
      street,
      postalCode,
      city,
      faxNumber,
      isPaid,
      declarationChecked,
      authorizationChecked,
    } = formValues;

    const isValid =
      name &&
      addressNumber &&
      country &&
      phoneNumber &&
      email &&
      (selectedValue === '2' || selectedValue === '3' ? memberId : true) &&
      (selectedValue === '2' || selectedValue === '3' ? province : true) &&
      (selectedValue === '1' || selectedValue === '2' || selectedValue === '3' || selectedValue === '4' || selectedValue === '5' ? companyName : true) &&
      firstName &&
      street &&
      postalCode &&
      city &&
      faxNumber &&
      isPaid &&
      declarationChecked &&
      authorizationChecked;

    onValidationChange(isValid);
  };

  useEffect(() => {
    validateForm();
  }, [formValues, selectedValue]);

  return (
    <>
      <Container size="2" >
        <Card m="20px"style={{boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",}}>
          <div style={{ margin: "20px" }}>
            <Text size="6">Informations Personelles</Text>
          </div>
          <Grid columns={{ initial: '1', md: '2' }} gap="3" width="auto">
            <Box ml="20px" mt="20px" mb="35px" height="auto">
              <Text>Je suis *</Text>
              <Flex mt="12px" mb="12px" direction="column" maxWidth="240px">
                <Select.Root defaultValue="" onValueChange={handleSelectChange}>
                  <Select.Trigger placeholder="Je suis ..." variant="surface" />
                  <Select.Content>
                    <Select.Item value="0">Un membre de la famille ou un ami</Select.Item>
                    <Select.Item value="1">Un membre d'une organisation non gouvernementale ou religieuse</Select.Item>
                    <Select.Item value="2">Un membre du Collège des consultants en immigration et en citoyenneté (CCIC)</Select.Item>
                    <Select.Item value="3">Un membre d'un barreau d'une province ou d'un territoire du Canada</Select.Item>
                    <Select.Item value="4">Un membre de la Chambre des notaires du Québec</Select.Item>
                    <Select.Item value="5">Un agent de voyage</Select.Item>
                  </Select.Content>
                </Select.Root>
              </Flex>
              {(selectedValue === '2' || selectedValue === '3') && (
                <>
                  <Text color="gray">Numéro d'identification du membre *</Text>
                  <Flex mt="12px" mb="12px" direction="column" maxWidth="240px">
                    <Box maxWidth="240px">
                      <TextField.Root
                        size="2"
                        placeholder="Nom de l'organisation"
                        name="memberId"
                        value={formValues.memberId}
                        onChange={handleInputChange}
                      />
                    </Box>
                  </Flex>
                </>
              )}
              {selectedValue === '4' && (
                <>
                  <Text color="gray">Numéro d'identification du membre *</Text>
                  <Flex mt="12px" mb="12px" direction="column" maxWidth="240px">
                    <Box maxWidth="240px">
                      <TextField.Root
                        size="2"
                        placeholder="Votre nom içi"
                        name="memberId"
                        value={formValues.memberId}
                        onChange={handleInputChange}
                      />
                    </Box>
                  </Flex>
                </>
              )}
              <Text>Nom *</Text>
              <Flex mt="12px" mb="12px" direction="column" maxWidth="240px">
                <Box maxWidth="240px">
                  <TextField.Root
                    size="2"
                    placeholder="Votre nom içi"
                    name="name"
                    value={formValues.name}
                    onChange={handleInputChange}
                  />
                </Box>
              </Flex>
              <Text>Numéro d'adresse *</Text>
              <Flex mt="12px" mb="12px" direction="column">
                <Box maxWidth="240px">
                  <TextField.Root
                    size="2"
                    name="addressNumber"
                    value={formValues.addressNumber}
                    onChange={handleInputChange}
                  />
                </Box>
              </Flex>
              <Text>Pays *</Text>
              <Flex mt="12px" mb="12px" direction="column">
                <Box maxWidth="240px">
                  <TextField.Root
                    size="2"
                    placeholder="Votre Pays d'habitation içi"
                    name="country"
                    value={formValues.country}
                    onChange={handleInputChange}
                  />
                </Box>
              </Flex>
              <Text>Numéro de Téléphone *</Text>
              <Flex mt="12px" mb="12px" direction="column">
                <Box maxWidth="240px">
                  <TextField.Root
                    size="2"
                    name="phoneNumber"
                    value={formValues.phoneNumber}
                    onChange={handleInputChange}
                  />
                </Box>
              </Flex>
              <Text>Adresse électronique *</Text>
              <Flex mt="12px" mb="12px" direction="column">
                <Box maxWidth="240px">
                  <TextField.Root
                    size="2"
                    placeholder="Votre Email içi"
                    name="email"
                    value={formValues.email}
                    onChange={handleInputChange}
                  />
                </Box>
              </Flex>
            </Box>
            <Box height="auto" ml="20px" mt="20px">
              {(selectedValue === '1' || selectedValue === '2' || selectedValue === '3' || selectedValue === '4' || selectedValue === '5') && (
                <>
                  <Text color="gray">Nom de l'entreprise ou l'organisation *</Text>
                  <Flex mt="12px" mb="12px" direction="column" maxWidth="240px">
                    <Box maxWidth="240px">
                      <TextField.Root
                        size="2"
                        placeholder="Nom de l'organisation"
                        name="companyName"
                        value={formValues.companyName}
                        onChange={handleInputChange}
                      />
                    </Box>
                  </Flex>
                </>
              )}
              {(selectedValue === '2' || selectedValue === '3') && (
                <>
                  <Text color="gray">De Quelle Province ou quel territoire *</Text>
                  <Flex mt="12px" mb="12px" direction="column">
                    <Box maxWidth="240px">
                      <TextField.Root
                        size="2"
                        name="province"
                        value={formValues.province}
                        onChange={handleInputChange}
                      />
                    </Box>
                  </Flex>
                </>
              )}
              <Text>Prénom</Text>
              <Flex mt="12px" mb="12px" direction="column">
                <Box maxWidth="240px">
                  <TextField.Root
                    size="2"
                    placeholder="Votre prénom içi"
                    name="firstName"
                    value={formValues.firstName}
                    onChange={handleInputChange}
                  />
                </Box>
              </Flex>
              <Text>Rue *</Text>
              <Flex mt="12px" mb="12px" direction="column">
                <Box maxWidth="240px">
                  <TextField.Root
                    size="2"
                    name="street"
                    value={formValues.street}
                    onChange={handleInputChange}
                  />
                </Box>
              </Flex>
              <Text>Code Postal *</Text>
              <Flex mt="12px" mb="12px" direction="column">
                <Box maxWidth="240px">
                  <TextField.Root
                    size="2"
                    name="postalCode"
                    value={formValues.postalCode}
                    onChange={handleInputChange}
                  />
                </Box>
              </Flex>
              <Text>Ville *</Text>
              <Flex mt="12px" mb="12px" direction="column">
                <Box maxWidth="240px">
                  <TextField.Root
                    size="2"
                    name="city"
                    value={formValues.city}
                    onChange={handleInputChange}
                  />
                </Box>
              </Flex>
              <Text>Numéro de Télécopieur *</Text>
              <Flex mt="12px" mb="12px" direction="column">
                <Box maxWidth="240px">
                  <TextField.Root
                    size="2"
                    name="faxNumber"
                    value={formValues.faxNumber}
                    onChange={handleInputChange}
                  />
                </Box>
              </Flex>
            </Box>
          </Grid>
          <Flex mr="20px" ml="20px">
            <Text>Êtes-vous payé pour représenter le demandeur et compléter ce formulaire en son nom? *</Text>
          </Flex>
          <Flex ml="20px" mt="12px" asChild gap="2">
            <Text as="label" size="2">
              <Radio
                size="1"
                name="isPaid"
                value="1"
                checked={formValues.isPaid === '1'}
                onChange={handleInputChange}
              />
              Oui
            </Text>
          </Flex>
          <Flex ml="20px" mb="12px" asChild gap="2">
            <Text as="label" size="2">
              <Radio
                size="1"
                name="isPaid"
                value="2"
                checked={formValues.isPaid === '2'}
                onChange={handleInputChange}
              />
              Non
            </Text>
          </Flex>
          <Flex m="20px">
            <Text size="6">Déclaration du Représentant *</Text>
          </Flex>
          <Flex ml="20px" mr="20px">
            <Text as="label" size="2">
              <Flex gap="2">
                  <Checkbox
                    name="declarationChecked"
                    onCheckedChange={(checked) => handleCheckboxChange('declarationChecked', checked)}
                  />
                  Je certifie que les coordonnées et les renseignements personnels fournis ci-dessus sont exacts, complets et véridiques.
              </Flex>
            </Text>
          </Flex>
          <Flex m="20px">
            <Text size="6">Autorisation du Représentant *</Text>
          </Flex>
          <Flex ml="20px" mr="20px" mb="35px">
            <Text as="label" size="2">
              <Flex gap="2">
                  <Checkbox
                    name="authorizationChecked"
                    onCheckedChange={(checked) => handleCheckboxChange('authorizationChecked', checked)}
                  />
                  Je comprends et j'accepte d'être la personne désignée par le demandeur pour gérer ses interactions ou celles d'un répondant avec Immigration, Réfugiés et Citoyenneté Canada ainsi qu'avec l'Agence des services frontaliers du Canada.
              </Flex>
            </Text>
          </Flex>
        </Card>
      </Container>
    </>
  );
};

export default Step3;