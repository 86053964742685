import React, { useState } from 'react';
import { Card, Container, Text, TextField, Button } from "@radix-ui/themes";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { API_URL } from '../config';

const Suivi = () => {
  const [orderId, setOrderId] = useState('');
  const [orderStatus, setOrderStatus] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await fetch(`${API_URL}/procces-status/${orderId}/`);

      if (response.status === 404) {
        setOrderStatus({
          id: orderId,
          status: 'Aucun dossier associer a ce code',
          message: 'Merci de corriger le code ou contacter le support pour plus de details.',
          icon: faTimesCircle,
          color: 'red'
        });
      } else {
        const data = await response.json();

        let status;
        if (data.error) {
          status = {
            id: orderId,
            status: 'Aucun dossier associer a ce code',
            message: data.error,
            icon: faTimesCircle,
            color: 'red'
          };
        } else if (data.ProccesStatus === true) {
          status = {
            id: orderId,
            status: 'Votre Ave a été bien traité',
            message: 'Notre support vous contactera pour plus de details.',
            icon: faCheckCircle,
            color: 'green'
          };
        } else if (data.ProccesStatus === false) {
          status = {
            id: orderId,
            status: 'Votre Ave en cours de traitement',
            message: 'Notre support vous contactera pour plus de details.',
            icon: faSpinner,
            color: 'orange'
          };
        } else {
          status = {
            id: orderId,
            status: 'Aucun dossier associer a ce code',
            message: 'Merci de corriger le code ou contacter le support pour plus de details.',
            icon: faTimesCircle,
            color: 'red'
          };
        }

        setOrderStatus(status);
      }
    } catch (error) {
      console.error('Error fetching order status:', error);
      setOrderStatus({
        id: orderId,
        status: 'Erreur lors de la récupération du statut',
        message: 'Merci de réessayer plus tard.',
        icon: faTimesCircle,
        color: 'red'
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container size="2">
      <Card style={{ margin: '40px', padding: '40px' }}>
        <Text size="6" style={{ marginBottom: '16px' }}>Suivi de votre commande</Text>
        <form onSubmit={handleSubmit}>
          <TextField.Root
            size="2"
            style={{ margin: '20px', borderRadius: '8px' }}
            placeholder="Entrez votre ID de commande"
            value={orderId}
            onChange={(e) => setOrderId(e.target.value)}
          />
          <Button type="submit" style={{ margin: '20px' }} variant="solid" disabled={loading}>
            {loading ? 'Chargement...' : 'Soumettre'}
          </Button>
        </form>
        {orderStatus && (
          <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
            <FontAwesomeIcon icon={orderStatus.icon} size="3x" color={orderStatus.color} style={{ marginRight: '20px' }} />
            <div>
              <Text size="5">{orderStatus.status}</Text>
              <p>{orderStatus.message}</p>
            </div>
          </div>
        )}
      </Card>
    </Container>
  );
};

export default Suivi;
