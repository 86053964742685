import React from 'react'
import {Text,Flex,Select,Box} from "@radix-ui/themes"


const JobType = ({selectedValue2,value,setfunction}) => {
  return (
    <div><Box><Text >Désignation de l'emploi *</Text></Box>
                <Flex mt="12px" mb="12px" direction="column" maxWidth="240px">
                <Select.Root defaultValue="" value={value} onValueChange={setfunction}>
                  <Select.Trigger placeholder="Veuillez sélectionner" variant="surface" />
                  <Select.Content>
                    {selectedValue2 === "0" && (
                      <>
                        <Select.Item value="Adjoints administratifs/adjointe...">Adjoints administratifs/adjointes administratives de bureau - général, juridique et médical</Select.Item>
                        <Select.Item value="Commis de bibliothèque...">Commis de bibliothèque, de correspondance et autres commis</Select.Item>
                        <Select.Item value="Commis en bureautique et en éditique">Commis en bureautique et en éditique</Select.Item>
                        <Select.Item value="Finance, assurance...">Finance, assurance et personnel de soutien administratif</Select.Item>
                        <Select.Item value="Personnel administratif...">Personnel administratif et de réglementation</Select.Item>
                        <Select.Item value="Personnel au courrier...">Personnel au courrier et à la distribution de messages</Select.Item>
                        <Select.Item value="Personnel au travail...">Personnel au travail général de bureau</Select.Item>
                        <Select.Item value="Personnel en finance, assurance...">Personnel en finance, assurance et personnel assimilé en administration des affaires, assurance et personnel assimilé en administration des affaires</Select.Item>
                        <Select.Item value="Personnel en logistiques...">Personnel en logistiques de chaîne d'approvisionnement, en suivi et en coordination d'horaires</Select.Item>
                        <Select.Item value="Professionnels/professionnelles en finance...">Professionnels/professionnelles en finance, en vérification et en comptabilité</Select.Item>
                        <Select.Item value="Professionnels/professionnelles en gestion...">Professionnels/professionnelles en gestion des ressources humaines et en services aux entreprises</Select.Item>
                        <Select.Item value="Sténographes judiciaires...">Sténographes judiciaires, transcripteurs/transcriptrices, techniciens/techniciennes à la gestion des documents et agents/agentes de statistiques</Select.Item>
                        <Select.Item value="Superviseurs/superviseures...">Superviseurs/superviseures de services administratifs</Select.Item>
                      </>
                    )}
                    {selectedValue2 === "1" && (
                      <>
                        <Select.Item value="Annonceurs/annonceures...">Annonceurs/annonceures et autres artistes de spectacle</Select.Item>
                        <Select.Item value="Athlètes, entraîneurs...">Athlètes, entraîneurs/entraîneuses, arbitres et personnel assimilé des sports et des loisirs</Select.Item>
                        <Select.Item value="Concepteurs/conceptrices...">Concepteurs/conceptrices artistiques et artisans/artisanes</Select.Item>
                        <Select.Item value="Personnel technique...">Personnel technique des bibliothèques, des archives publiques, des musées et des galeries d'art</Select.Item>
                        <Select.Item value="Photographes, techniciens...">Photographes, techniciens/techniciennes en graphisme, personnel technique et personnel de coordination du cinéma, de la radiotélédiffusion et des arts de la scène</Select.Item>
                        <Select.Item value="Professionnels/professionnelles de la rédaction...">Professionnels/professionnelles de la rédaction, de la traduction et personnel professionnel assimilé des communications</Select.Item>
                        <Select.Item value="Professionnels/professionnelles des arts plastiques...">Professionnels/professionnelles des arts plastiques et des arts de la scène</Select.Item>
                        <Select.Item value="Professionnels/professionnelles des bibliothèques...">Professionnels/professionnelles des bibliothèques, des archives, des musées et des galeries d'art</Select.Item>

                      </>
                    )}
                    {selectedValue2 === "3" && (
                      <>
                        <Select.Item value="Agents/agentes de sécurité...">Agents/agentes de sécurité et personnel assimilé des services de sécurité</Select.Item>
                        <Select.Item value="Autre personnel de soutien...">Autre personnel de soutien aux ventes et personnel assimilé</Select.Item>
                        <Select.Item value="Autre personnel des services...">Autre personnel des services personnels</Select.Item>
                        <Select.Item value="Autres services de soutien...">Autres services de soutien et personnel assimilé</Select.Item>
                        <Select.Item value="Bouchers/bouchères...">Bouchers/bouchères et boulangers-pâtissiers/boulangères-pâtissières</Select.Item>
                        <Select.Item value="Caissiers/caissières">Caissiers/caissières</Select.Item>
                        <Select.Item value="Chefs et cuisiniers/cuisinières">Chefs et cuisiniers/cuisinières</Select.Item>
                        <Select.Item value="Nettoyeurs/nettoyeuses">Nettoyeurs/nettoyeuses</Select.Item>
                        <Select.Item value="Personnel de l'assurance...">Personnel de l'assurance, de l'immobilier et des ventes financières</Select.Item>
                        <Select.Item value="Personnel de l'hébergement...">Personnel de l'hébergement et des voyages</Select.Item>
                        <Select.Item value="Personnel de soutien en services">Personnel de soutien en services d'hébergement, de voyage et de loisirs</Select.Item>
                        <Select.Item value="Personnel des services de tourisme...">Personnel des services de tourisme et de loisirs</Select.Item>
                        <Select.Item value="Personnel des services des aliments">Personnel des services des aliments et des boissons</Select.Item>
                        <Select.Item value="Personnel spécialisé en services...">Personnel spécialisé en services personnels et en service à la clientèle</Select.Item>
                        <Select.Item value="Personnel technique spécialisé...">Personnel technique spécialisé du commerce de gros et acheteurs/acheteuses du commerce de détail et de gros</Select.Item>
                        <Select.Item value="Représentants/représentantes des ventes...">Représentants/représentantes des ventes et des comptes- commerce de gros</Select.Item>
                        <Select.Item value="Représentants/représentantes du service...">Représentants/représentantes du service à la clientèle et de l'information</Select.Item>
                        <Select.Item value="Serveurs/serveuses au comptoir...">Serveurs/serveuses au comptoir, aides de cuisine et personnel de soutien assimilé</Select.Item>
                        <Select.Item value="Superviseurs/superviseures de services">Superviseurs/superviseures de services</Select.Item>
                        <Select.Item value="Superviseurs/superviseures des ventes...">Superviseurs/superviseures des ventes - commerce de détail</Select.Item>
                        <Select.Item value="Vendeurs/vendeuses...">Vendeurs/vendeuses - commerce de détail</Select.Item>
                      </>
                    )}
                    {selectedValue2 === "4" && (
                      <>
                        <Select.Item value="Dispensateurs/dispensatrices...">Dispensateurs/dispensatrices de soins en milieu familial et personnel de soutien en enseignement</Select.Item>
                        <Select.Item value="Enseignants/enseignantes au niveau collégial...">Enseignants/enseignantes au niveau collégial et autres instructeurs/instructrices en formation professionnelle</Select.Item>
                        <Select.Item value="Enseignants/enseignantes aux niveaux secondaire...">Enseignants/enseignantes aux niveaux secondaire, primaire et préscolaire et conseillers/conseillères d'orientation</Select.Item>
                        <Select.Item value="Juges, avocats...">Juges, avocats/avocates et notaires</Select.Item>
                        <Select.Item value="Personnel des services de protection...">Personnel des services de protection public de première ligne</Select.Item>
                        <Select.Item value="Personnel juridique et de soutien">Personnel juridique et de soutien des services de protection public</Select.Item>
                        <Select.Item value="Personnel paraprofessionnel des services...">Personnel paraprofessionnel des services juridiques, sociaux, communautaires et de l'enseignement</Select.Item>
                        <Select.Item value="Professeurs/professeures d'université...">Professeurs/professeures d'université et assistants/assistantes d'enseignement au niveau postsecondaire</Select.Item>
                        <Select.Item value="Professionnels/professionnelles des services...">Professionnels/professionnelles des services sociaux et communautaires</Select.Item>
                        <Select.Item value="Recherchistes, experts-conseils...">Recherchistes, experts-conseils/expertes-conseils et agents/agentes des politiques et des programmes</Select.Item>

                      </>
                    )}
                    {selectedValue2 === "6" && (
                      <>
                        <Select.Item value="Autre personnel de montage...">Autre personnel de montage et personnel assimilé</Select.Item>
                        <Select.Item value="Manoeuvres dans la transformation...">Manoeuvres dans la transformation, la fabrication et les services d'utilité publique</Select.Item>
                        <Select.Item value="Monteurs/monteuses...">Monteurs/monteuses de matériel mécanique, électrique et électronique</Select.Item>
                        <Select.Item value="Opérateurs/opératrices de machines dans la production...">Opérateurs/opératrices de machines dans la production des pâtes et papiers et dans la transformation et la fabrication du bois et personnel assimilé</Select.Item>
                        <Select.Item value="Opérateurs/opératrices de machines dans la transformation...">Opérateurs/opératrices de machines dans la transformation et la confection de produits textiles, d'articles en tissu, en fourrure et en cuir et personnel assimilé</Select.Item>
                        <Select.Item value="Opérateurs/opératrices de machines dans le traitement de produits...">Opérateurs/opératrices de machines dans le traitement de produits chimiques, du caoutchouc et du plastique et personnel assimilé</Select.Item>
                        <Select.Item value="Opérateurs/opératrices de machines dans le traitement et la fabrication...">Opérateurs/opératrices de machines dans le traitement et la fabrication des métaux et des minerais et personnel assimilé</Select.Item>
                        <Select.Item value="Opérateurs/opératrices de machines et personnel assimilé...">Opérateurs/opératrices de machines et personnel assimilé dans la transformation des aliments et boissons et produits connexes</Select.Item>
                        <Select.Item value="Opérateurs/opératrices de poste...">Opérateurs/opératrices de poste central de contrôle dans les procédés de transformation et de fabrication</Select.Item>
                        <Select.Item value="Opérateurs/opératrices d'équipement">Opérateurs/opératrices d'équipement à imprimer et personnel assimilé</Select.Item>
                        <Select.Item value="Opérateurs/opératrices et contrôleurs">Opérateurs/opératrices et contrôleurs/contrôleuses d'équipements d'utilités publiques</Select.Item>
                        <Select.Item value="Surveillants/surveillantes dans la fabrication...">Surveillants/surveillantes dans la fabrication et le montage</Select.Item>
                        <Select.Item value="Surveillants/surveillantes dans la transformation...">Surveillants/surveillantes dans la transformation et la fabrication</Select.Item>

                      </>
                    )}
                    {selectedValue2 === "7" && (
                      <>
                        <Select.Item value="Aumônier">Aumônier</Select.Item>
                        <Select.Item value="Avocat/Avocate">Avocat/Avocate</Select.Item>
                        <Select.Item value="Chercheur/Chercheuse en communications">Chercheur/Chercheuse en communications</Select.Item>
                        <Select.Item value="Commis de soutien à la gestion des ressources, Réserve aérienne">Commis de soutien à la gestion des ressources, Réserve aérienne</Select.Item>
                        <Select.Item value="Commis de soutien à la gestion des ressources, Réserve de l'Armée de terre">Commis de soutien à la gestion des ressources, Réserve de l'Armée de terre</Select.Item>
                        <Select.Item value="Commis de soutien à la gestion des ressources, Réserve navale">Commis de soutien à la gestion des ressources, Réserve navale</Select.Item>
                        <Select.Item value="Communicateur naval/Communicatrice navale">Communicateur naval/Communicatrice navale</Select.Item>
                        <Select.Item value="Conducteur/Conductrice...">Conducteur/Conductrice de matériel mobile de soutien</Select.Item>
                        <Select.Item value="Cuisinier/Cuisinière">Cuisinier/Cuisinière</Select.Item>
                        <Select.Item value="Dentiste">Dentiste</Select.Item>
                        <Select.Item value="Infirmier/Infirmière">Infirmier/Infirmière</Select.Item>
                        <Select.Item value="Logistique (soutien), Officier, Réserve navale">Logistique (soutien), Officier, Réserve navale</Select.Item>
                        <Select.Item value="Manoeuvrier">Manoeuvrier</Select.Item>
                        <Select.Item value="Médecin">Médecin</Select.Item>
                        <Select.Item value="Musicien/Musicienne">Musicien/Musicienne</Select.Item>
                        <Select.Item value="Officier d'administration des services de santé">Officier d'administration des services de santé</Select.Item>
                        <Select.Item value="Officier d'artillerie">Officier d'artillerie</Select.Item>
                        <Select.Item value="Officier de la logistique">Officier de la logistique</Select.Item>
                        <Select.Item value="Officier de la police militaire">Officier de la police militaire</Select.Item>
                        <Select.Item value="Officier de la sélection du personnel">Officier de la sélection du personnel</Select.Item>
                        <Select.Item value="Officier des affaires publiques">Officier des affaires publiques</Select.Item>
                        <Select.Item value="Officier des blindés">Officier des blindés</Select.Item>
                        <Select.Item value="Officier des opérations maritimes...">Officier des opérations maritimes de surface et sous-marines</Select.Item>
                        <Select.Item value="Officier des systèmes de combat aérien">Officier des systèmes de combat aérien</Select.Item>
                        <Select.Item value="Officier des transmissions">Officier des transmissions</Select.Item>
                        <Select.Item value="Officier d'infanterie">Officier d'infanterie</Select.Item>
                        <Select.Item value="Officier du contrôle aérospatial">Officier du contrôle aérospatial</Select.Item>
                        <Select.Item value="Officier du génie">Officier du génie</Select.Item>
                        <Select.Item value="Officier du génie aérospatial">Officier du génie aérospatial</Select.Item>
                        <Select.Item value="Officier du génie des communications...">Officier du génie des communications et électronique (Aérien)</Select.Item>
                        <Select.Item value="Officier du génie électrique...">Officier du génie électrique et mécanique</Select.Item>
                        <Select.Item value="Officier du renseignement...">Officier du renseignement, Réserve navale</Select.Item>
                        <Select.Item value="Opérateur/Opératrice de contrôle">Opérateur/Opératrice de contrôle aérospatial</Select.Item>
                        <Select.Item value="Opérateur/Opératrice d'équipement">Opérateur/Opératrice d'équipement d'information de combat (Marine)</Select.Item>
                        <Select.Item value="Opérateur/Opératrice des transmissions">Opérateur/Opératrice des transmissions</Select.Item>
                        <Select.Item value="Pharmacien/Pharmacienne">Pharmacien/Pharmacienne</Select.Item>
                        <Select.Item value="Pilote">Pilote</Select.Item>
                        <Select.Item value="Plongeur d'inspection portuaire">Plongeur d'inspection portuaire</Select.Item>
                        <Select.Item value="Police militaire">Police militaire</Select.Item>
                        <Select.Item value="Pompier/Pompière">Pompier/Pompière</Select.Item>
                        <Select.Item value="Poseur/Poseuse de lignes">Poseur/Poseuse de lignes</Select.Item>
                        <Select.Item value="Sapeur/Sapeuse de combat">Sapeur/Sapeuse de combat</Select.Item>
                        <Select.Item value="Soldat d'infanterie">Soldat d'infanterie</Select.Item>
                        <Select.Item value="Soldat de l'artillerie (défense aérienne)">Soldat de l'artillerie (défense aérienne)</Select.Item>
                        <Select.Item value="Soldat de l'artillerie (campagne)">Soldat de l'artillerie (campagne)</Select.Item>
                        <Select.Item value="Soldat des blindés">Soldat des blindés</Select.Item>
                        <Select.Item value="Spécialiste du renseignement">Spécialiste du renseignement</Select.Item>
                        <Select.Item value="Technicien médical/Technicienne médicale">Technicien médical/Technicienne médicale</Select.Item>
                        <Select.Item value="Technicien/Technicienne d'armement / Terre">Technicien/Technicienne d'armement / Terre</Select.Item>
                        <Select.Item value="Technicien/Technicienne de la construction">Technicien/Technicienne de la construction</Select.Item>
                        <Select.Item value="Technicien/Technicienne de systèmes">Technicien/Technicienne de systèmes d'informations et télécommunications aérospatiales</Select.Item>
                        <Select.Item value="Technicien/Technicienne de véhicules, Réserve de l'armée">Technicien/Technicienne de véhicules, Réserve de l'armée</Select.Item>
                        <Select.Item value="Technicien/Technicienne des mouvements">Technicien/Technicienne des mouvements</Select.Item>
                        <Select.Item value="Technicien/Technicienne en approvisionnement, Réserve aérienne">Technicien/Technicienne en approvisionnement, Réserve aérienne</Select.Item>
                        <Select.Item value="Technicien/Technicienne en approvisionnement, Réserve de l'Armée de terre">Technicien/Technicienne en approvisionnement, Réserve de l'Armée de terre</Select.Item>
                        <Select.Item value="Technicien/Technicienne en approvisionnement, Réserve navale">Technicien/Technicienne en approvisionnement, Réserve navale</Select.Item>
                        <Select.Item value="Technicien/Technicienne en distribution électrique">Technicien/Technicienne en distribution électrique</Select.Item>
                        <Select.Item value="Technicien/Technicienne en eau, produits...">Technicien/Technicienne en eau, produits pétroliers et environnement</Select.Item>
                        <Select.Item value="Technicien/Technicienne en géomatique">Technicien/Technicienne en géomatique</Select.Item>
                        <Select.Item value="Technicien/Technicienne en groupes électrogènes">Technicien/Technicienne en groupes électrogènes</Select.Item>
                        <Select.Item value="Technicien/Technicienne en imagerie">Technicien/Technicienne en imagerie</Select.Item>
                        <Select.Item value="Technicien/Technicienne en plomberie...">Technicien/Technicienne en plomberie et chauffage</Select.Item>
                        <Select.Item value="Technicien/Technicienne en réfrigération...">Technicien/Technicienne en réfrigération et mécanique</Select.Item>
                        <Select.Item value="Technicien/Technicienne en structures...">Technicien/Technicienne en structures d'aéronefs</Select.Item>
                        <Select.Item value="Technicien/Technicienne en systèmes aéronautiques">Technicien/Technicienne en systèmes aéronautiques</Select.Item>
                        <Select.Item value="Technicien/Technicienne en systèmes avioniques">Technicien/Technicienne en systèmes avioniques</Select.Item>
                        <Select.Item value="Technicien/Technicienne systèmes d'informations">Technicien/Technicienne systèmes d'informations et communications</Select.Item>
                        <Select.Item value="Technologue en électronique biomédicale">Technologue en électronique biomédicale</Select.Item>
                      </>
                    )}
                    {selectedValue2 === "8" && (
                      <>
                        <Select.Item value="Directeurs/directrices - commerce...">Directeurs/directrices - commerce de gros et de détail</Select.Item>
                        <Select.Item value="Directeurs/directrices de la construction....">Directeurs/directrices de la construction, de l'exploitation et de l'entretien d'immeubles</Select.Item>
                        <Select.Item value="Directeurs/directrices de la fabrication...">Directeurs/directrices de la fabrication et des services d'utilité publique</Select.Item>
                        <Select.Item value="Directeurs/directrices de la fonction...">Directeurs/directrices de la fonction publique</Select.Item>
                        <Select.Item value="Directeurs/directrices de la restauration...">Directeurs/directrices de la restauration et des services d'hébergement</Select.Item>
                        <Select.Item value="Directeurs/directrices de l'enseignement...">Directeurs/directrices de l'enseignement et des services sociaux et communautaires</Select.Item>
                        <Select.Item value="Directeurs/directrices de l'exploitation...">Directeurs/directrices de l'exploitation des ressources naturelles et de la pêche</Select.Item>
                        <Select.Item value="Directeurs/directrices des arts...">Directeurs/directrices des arts, de la culture, des sports et des loisirs</Select.Item>
                        <Select.Item value="Directeurs/directrices des services administratifs">Directeurs/directrices des services administratifs</Select.Item>
                        <Select.Item value="Directeurs/directrices des services de communications">Directeurs/directrices des services de communications</Select.Item>
                        <Select.Item value="Directeurs/directrices des services de génie...">Directeurs/directrices des services de génie, d'architecture, de sciences et des systèmes informatiques</Select.Item>
                        <Select.Item value="Directeurs/directrices des services de la protection...">Directeurs/directrices des services de la protection du public</Select.Item>
                        <Select.Item value="Directeurs/directrices des services financiers...">Directeurs/directrices des services financiers et des services aux entreprises</Select.Item>
                        <Select.Item value="Directeurs/directrices des soins de santé...">Directeurs/directrices des soins de santé</Select.Item>
                        <Select.Item value="Directeurs/directrices des transports...">Directeurs/directrices des transports</Select.Item>
                        <Select.Item value="Directeurs/directrices des ventes corporatives">Directeurs/directrices des ventes corporatives</Select.Item>
                        <Select.Item value="Directeurs/directrices du service à la clientèle...">Directeurs/directrices du service à la clientèle et des services personnels</Select.Item>
                        <Select.Item value="Gestionnaires en agriculture...">Gestionnaires en agriculture, en horticulture et en aquaculture</Select.Item>
                        <Select.Item value="Membres des corps législatifs...">Membres des corps législatifs et cadres supérieurs/cadres supérieures, y compris les représentants/représentantes politiques</Select.Item>
                      </>
                    )}
                    {selectedValue2 === "9" && (
                      <>
                        <Select.Item value="Aides de soutien...">Aides de soutien des métiers et manoeuvres</Select.Item>
                        <Select.Item value="Autre personnel des métiers de la construction...">Autre personnel des métiers de la construction</Select.Item>
                        <Select.Item value="Autres conducteurs/conductrices...">Autres conducteurs/conductrices de matériel de transport et personnel d'entretien assimilé</Select.Item>
                        <Select.Item value="Autres mécaniciens/mécaniciennes...">Autres mécaniciens/mécaniciennes et réparateurs/réparatrices connexes</Select.Item>
                        <Select.Item value="Charpentiers/charpentières...">Charpentiers/charpentières et ébénistes</Select.Item>
                        <Select.Item value="Conducteurs/conductrices de véhicules">Conducteurs/conductrices de véhicules automobiles et de véhicules de transport en commun</Select.Item>
                        <Select.Item value="Conducteurs/conductrices d'équipement lourd">Conducteurs/conductrices d'équipement lourd</Select.Item>
                        <Select.Item value="Débardeurs/débardeuses...">Débardeurs/débardeuses et manutentionnaires</Select.Item>
                        <Select.Item value="Électriciens/électriciennes...">Électriciens/électriciennes et monteurs/monteuses de lignes d'électricité et de télécommunications</Select.Item>
                        <Select.Item value="Entrepreneurs/entrepreneuses et contremaîtres/ contremaîtresses du personnel des métiers d'entretien...">Entrepreneurs/entrepreneuses et contremaîtres/ contremaîtresses du personnel des métiers d'entretien et des opérateurs d'équipement lourd et de transport</Select.Item>
                        <Select.Item value="Entrepreneurs/entrepreneuses et contremaîtres/ contremaîtresses du personnel des métiers industriels...">Entrepreneurs/entrepreneuses et contremaîtres/ contremaîtresses du personnel des métiers industriels, de la construction, de l'électricité et du personnel assimilé</Select.Item>
                        <Select.Item value="Grutiers/grutières...">Grutiers/grutières, foreurs/foreuses et dynamiteurs/dynamiteuses</Select.Item>
                        <Select.Item value="Manoeuvres aux travaux...">Manoeuvres aux travaux publics et personnel assimilé</Select.Item>
                        <Select.Item value="Mécaniciens/mécaniciennes de machinerie...">Mécaniciens/mécaniciennes de machinerie et d'équipement de transport</Select.Item>
                        <Select.Item value="Mécaniciens/mécaniciennes de véhicules...">Mécaniciens/mécaniciennes de véhicules automobiles</Select.Item>
                        <Select.Item value="Personnel de l'imprimerie...">Personnel de l'imprimerie, autre personnel des métiers et personnel assimilé</Select.Item>
                        <Select.Item value="Personnel de maçonnerie...">Personnel de maçonnerie et de plâtrage</Select.Item>
                        <Select.Item value="Personnel des métiers d'usinage...">Personnel des métiers d'usinage, du formage, du profilage et du montage du métal</Select.Item>
                        <Select.Item value="Personnel des opérations...">Personnel des opérations du transport ferroviaire</Select.Item>
                        <Select.Item value="Personnel d'installation...">Personnel d'installation, de réparation et d'entretien</Select.Item>
                        <Select.Item value="Plombiers/plombières...">Plombiers/plombières, tuyauteurs/tuyauteuses et monteurs/monteuses d'installations au gaz</Select.Item>
                      </>
                    )}
                    {selectedValue2 === "11" && (
                      <>
                        <Select.Item value="Autre personnel de la pêche...">Autre personnel de la pêche et personnel du trappage et de la chasse</Select.Item>
                        <Select.Item value="Capitaines de bateaux...">Capitaines de bateaux de pêche et pêcheurs/pêcheuses</Select.Item>
                        <Select.Item value="Conducteurs/conductrices de machines...">Conducteurs/conductrices de machines d'abattage d'arbres</Select.Item>
                        <Select.Item value="Entrepreneurs/entrepreneuses et surveillants/surveillantes de l'exploitation...">Entrepreneurs/entrepreneuses et surveillants/surveillantes de l'exploitation des mines, du pétrole et du gaz</Select.Item>
                        <Select.Item value="Entrepreneurs/entrepreneuses et surveillants/surveillantes en agriculture...">Entrepreneurs/entrepreneuses et surveillants/surveillantes en agriculture, en horticulture et opérations et services connexes</Select.Item>
                        <Select.Item value="Manoeuvres à la récolte...">Manoeuvres à la récolte, en aménagement paysager et en ressources naturelles</Select.Item>
                        <Select.Item value="Personnel de l'exploitation...">Personnel de l'exploitation forestière</Select.Item>
                        <Select.Item value="Personnel d'entretien des mines...">Personnel d'entretien des mines et du forage des puits de pétrole et de gaz</Select.Item>
                        <Select.Item value="Personnel du forage...">Personnel du forage, des mines souterraines et de l'extraction de pétrole et de gaz, et autre personnel assimilé</Select.Item>
                        <Select.Item value="Personnel en agriculture...">Personnel en agriculture et en horticulture</Select.Item>
                        <Select.Item value="Surveillants/surveillantes de l'exploitation forestière">Surveillants/surveillantes de l'exploitation forestière</Select.Item>

                      </>
                    )}
                    {selectedValue2 === "13" && (
                      <>
                        <Select.Item value="Autres contrôleurs...">Autres contrôleurs/contrôleuses techniques et officiers/officières de réglementation</Select.Item>
                        <Select.Item value="Autres professionnels...">Autres professionnels/ professionnelles en génie</Select.Item>
                        <Select.Item value="Mathématiciens/ mathématiciennes...">Mathématiciens/ mathématiciennes, statisticiens/statisticiennes et actuaires</Select.Item>
                        <Select.Item value="Officiers/officières et contrôleurs...">Officiers/officières et contrôleurs/contrôleuses des services de transport</Select.Item>
                        <Select.Item value="Personnel technique des sciences de la vie">Personnel technique des sciences de la vie</Select.Item>
                        <Select.Item value="Personnel technique des sciences physiques">Personnel technique des sciences physiques</Select.Item>
                        <Select.Item value="Personnel technique en architecture">Personnel technique en architecture, en dessin, en arpentage, en géomatique et en météorologie</Select.Item>
                        <Select.Item value="Personnel technique en génie civil...">Personnel technique en génie civil, mécanique et industriel</Select.Item>
                        <Select.Item value="Personnel technique en génie électronique...">Personnel technique en génie électronique et électrique</Select.Item>
                        <Select.Item value="Personnel technique en informatique">Personnel technique en informatique</Select.Item>
                        <Select.Item value="Professionnels/professionnelles des sciences de la vie">Professionnels/professionnelles des sciences de la vie</Select.Item>
                        <Select.Item value="Professionnels/professionnelles des sciences physiques">Professionnels/professionnelles des sciences physiques</Select.Item>
                        <Select.Item value="Professionnels/professionnelles en architecture">Professionnels/professionnelles en architecture, en urbanisme et en arpentage</Select.Item>
                        <Select.Item value="Professionnels/professionnelles en génie civil">Professionnels/professionnelles en génie civil, mécanique, électrique et chimique</Select.Item>
                        <Select.Item value="Professionnels/professionnelles en informatique">Professionnels/professionnelles en informatique</Select.Item>

                      </>
                    )}
                    {selectedValue2 === "14" && (
                      <>
                        <Select.Item value="Autre personnel technique">Autre personnel technique en soins de santé</Select.Item>
                        <Select.Item value="Médecins, dentistes et vétérinaires">Médecins, dentistes et vétérinaires</Select.Item>
                        <Select.Item value="Optométristes, chiropraticiens/ chiropraticiennes...">Optométristes, chiropraticiens/ chiropraticiennes et autres professionnels/professionnelles en diagnostic et en traitement de la santé</Select.Item>
                        <Select.Item value="Personnel de soutien des services de santé">Personnel de soutien des services de santé</Select.Item>
                        <Select.Item value="Personnel professionnel en soins infirmiers">Personnel professionnel en soins infirmiers</Select.Item>
                        <Select.Item value="Personnel technique en soins dentaires">Personnel technique en soins dentaires</Select.Item>
                        <Select.Item value="Pharmaciens/pharmaciennes, diététistes...">Pharmaciens/pharmaciennes, diététistes et nutritionnistes</Select.Item>
                        <Select.Item value="Professionnels/professionnelles en thérapie...">Professionnels/professionnelles en thérapie et en évaluation</Select.Item>
                        <Select.Item value="Technologues et techniciens/techniciennes...">Technologues et techniciens/techniciennes des sciences de la santé</Select.Item>
                      </>
                    )}
                  </Select.Content>
                </Select.Root></Flex></div>
  )
}

export default JobType